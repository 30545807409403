import React, { useRef, useState } from 'react'
import { Link } from "react-router-dom";
import {useMainContext} from "../../Contexts/ContextProvider"
import axiosClient from "../../Helpers/Axios-Client";
import {toast } from 'react-toastify';

function Login() 
{
  // const [errors,setErrors] = useState();
  
  const emailRef = useRef();
  const passwordRef = useRef();

  const {setCredentials} = useMainContext();

  const btnSignIn_OnClick = (e) => {
    e.preventDefault()

    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value
    };

    // setErrors(null);
    axiosClient.post("/login",payload).then(({data})=> {
      console.log(data);
      setCredentials(data.user,data.token);
    
    }).catch(err => {
      const response = err.response;

      console.log(response);

      if(response && response.status === 422)
      {
      
        if(response.data.errors)
        {
          Object.keys(response.data.errors).map(key => (
            toast.error(response.data.errors[key][0])
          ));
        }
        else
        {
          toast.error(response.data.message);
        }
      }
    });
  }

  return (
    
    <div className='hold-transition login-page'>
    
      <div className="login-box">
        <div className="login-logo" >
          <img src="/img/Escreen_Logo_Blue.png" alt="Logo" className="img-fluid" style={{width:"60%"}} />
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Login into your account.</p>
        
            {/* {
              errors && <div className="alert alert-danger " role='alert'>
                {
                  Object.keys(errors).map(key => (
                    <p key={key}>{errors[key][0]}</p>
                  ))
                }
              </div>
            } */}

            <form onSubmit={btnSignIn_OnClick}>

              <div className="input-group mb-3">
                <input  ref={emailRef} type="email" className="form-control" placeholder="Email" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>

              <div className="input-group mb-3">
                <input ref={passwordRef} type="password" className="form-control" placeholder="Password" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-success btn-block">Sign In</button>
                </div>
              
              </div>
            </form>
 
            <p className="mt-3 mb-1 text-center">
              <Link to={"/forgot-password"}>I forgot my password</Link>
            </p>

          </div>
          {/* /.login-card-body */}
        </div>
      </div>
    </div>
  )
}

export default Login