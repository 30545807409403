import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link,useLocation } from "react-router-dom";
import {useMainContext} from "../../Contexts/ContextProvider"

function Screens() 
{
    const {user} = useMainContext();
    const location = useLocation();
    const perantBranch = location.state;

    const initRowValues = {
        id:null,
        is_active:true,
        ar_name:"",
        en_name:"",
        link_token:"",
        status:0,
        brand:"",
        os:"",
        aspect_ratio:"",
        serial_number:"",
        branch: perantBranch,
    };

    // const [errors,setErrors] = useState(null);
    const [branches,setBranches] =  useState();
    const [screens,setScreens] =  useState();
    const [screen,setScreen] =  useState(initRowValues);
  
    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center:true,
            width:"60px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center:true,
            width:"100px",
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> :  <span className="badge badge-danger" >Inactive</span>,
        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
            // width:"100px",
        },

        // {
        //     name: 'Brand',
        //     selector: row => row.brand,
        //     sortable: true,
        //     center:true,
        //     // width:"100px",
        // },

        // {
        //     name: 'OS',
        //     selector: row => row.os,
        //     sortable: true,
        //     center:true,
        //     // width:"100px",
        // },

        // {
        //     name: 'Aspect Ratio',
        //     selector: row => row.aspect_ratio,
        //     sortable: true,
        //     center:true,
        // },

        // {
        //     name: 'Serial Number',
        //     selector: row => row.serial_number,
        //     sortable: true,
        //     center:true,
        // },

        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center:true,
            //  width:"100px",
            cell: row => {

                var status = "";
                switch (Number(row.status)) {
                    case 0:
                        status = <span className="badge badge-danger" >Not Linked</span>
                        break;

                    case 1:
                        status = <span className="badge badge-success" >Linked</span>
                        break;

                    case 2:
                        status = <span className="badge badge-success" >Connected</span>
                        break;

                    case 3:
                        status = <span className="badge badge-danger" >Disconnected</span>
                        break;

                    default:
                        status = <span className="badge badge-danger" >Unknow</span>

                }

                return status;
            } 
        },
        {
            name: 'Branch',
            selector: row => row.branch.en_name,
            sortable: true,
            cell: row => <span>{row.branch.en_name}</span>
        },
        {
            name: 'City',
            selector: row => row.branch.city.en_name,
            sortable: true,
            // width:"100px",
            cell: row => <span>{row.branch.city.en_name}</span>
        },
        {
            name: 'Company',
            selector: row => row.branch.company.en_name,
            sortable: true,
            cell: row => <div>
                            <img src={row.branch.company.logo ? `${process.env.REACT_APP_STORAGE_URL}/${row.branch.company.logo}` : "/img/No_Image_Available.jpg"} className="img-circle m-2 elevation-2" width="40" alt="Company Logo" />
                            <span>{row.branch.company.en_name}</span>
                         </div>
        },
        {
            name: 'Last read at',
            selector: row => row.last_read_at,
            sortable: true,
            center:true,
        },
        {
            name: '',
            selector: row => row.actions,
            center:true,
            width:"280px",
            cell: row => <>
                        {/* { user.slides_view !== 0 || user.slides_add !== 0 || user.slides_edit !== 0 || user.slides_delete !== 0 ? <Link state={row}  to={`${row.en_name.replace(/\s+/g, '-').toLowerCase()}/slides`} className="btn btn-warning btn-sm text-white mr-2"><i className="fa fa-photo-video"></i> Slides</Link> : "" } */}
                        { user.screens_edit !== 0 ?  <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : ""}
                        { user.screens_delete !== 0 ?  <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : ""}    
                        </>,
        },
    ];
    
    useEffect(()=>  {
        getBranches();
        getScreens();
      
    },[]);

    const getBranches = () => {

        axiosClient.get("/branches").then((response) => {

            //console.log(response.data);
            setBranches(response.data);
           
        }).catch((error)=> {

            console.log(error);
           
        });

    }

    const getScreens = () => {

        if (perantBranch && perantBranch.id) {
            axiosClient.get(`/screens/${perantBranch.id}`).then((response) => {

                console.log(response.data);
                setScreens(response.data);
               
            }).catch((error)=> {
    
                console.log(error);
            });
        }
        else {
            axiosClient.get("/screens").then((response) => {

                console.log(response.data);
                setScreens(response.data);
               
            }).catch((error)=> {
    
                console.log(error);
            });
        }

  
    }

    const onView = (selectedRow) => 
    {
        // setErrors(null);

        if(selectedRow)
        {
            setScreen(selectedRow);
        }
        else
        {
            setScreen(initRowValues);
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = () => {
        // setErrors(null);

        if(screen.id)
        {
            console.log("Edit");
            console.log(screen);

            axiosClient.put(`/screens/${screen.id}`,screen).then((response) => {

                console.log("Response : " , response)

                getScreens();
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${screen.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
    
            }).catch((error)=> {
    
                const response = error.response;

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }
                }
                else
                {
                    console.log(response);
                }

            });
        }
        else
        {
            console.log("Add");

            axiosClient.post(`/screens`,screen).then((response) => {

                getScreens();
                console.log("Response : " , response);

                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${screen.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
            }).catch((error)=> {
    
                const response = error.response;

                console.log(response);

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }
                }
                else
                {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
          }).then((result) => {

            if (result.isConfirmed) 
            {

                axiosClient.delete(`/screens/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    getScreens();

                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${screen.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                      });

                }).catch((error) => {

                    console.log(error);
                });

            }
          })

    }



  return (
    <div className="content-wrapper">
    
    <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">{perantBranch && perantBranch.en_name} Screens</h1>
            </div>
       
            <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to={"/dashboard/companies"} className="text-success">Companies</Link></li>

                <li className="breadcrumb-item active">{perantBranch && perantBranch.en_name} Screens</li>
            </ol>
            
            </div>
           
        </div>
        
        </div>
       
    </div>

    <div className="content">
        <div className="container-fluid">
        <div className="row">
            <div className="col-12">
            <div className="card">
                <div className="card-header text-right">
                     {user &&  user.screens_add !== 0 ? <button onClick={ev => onView(null)}  type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Screen</button> : "" }
                </div>
              
                <div className="card-body">
                    <div className="row ">
                        <div className="form-group offset-sm-10 col-sm-2 ">
                            <div className="input-group">
                            <input  type="search" className="form-control " placeholder="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-default">
                                <i className="fa fa-search" />
                                </button>
                            </div>
                            </div>

                        </div>

                    </div>

                    <DataTable className="table table-bordered" columns={columns} data={screens} pagination  />

                </div>
            </div>
            
            </div>
        
        </div>
        </div>
    </div>

        <button style={{display:"none"}}  ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
        <div  className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

            <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">{screen && screen.id  ? "Edit Screen" : "Add Screen"}</h5>
                <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div className="modal-body">
            {/* {
            errors && <div className="alert alert-danger " role='alert'>
                {
                Object.keys(errors).map(key => (
                    <p key={key}>{errors[key][0]}</p>
                ))
                }
            </div>
            } */}

            <form className="form-horizontal">
                <div className="form-group row">
                        <div className="offset-sm-3 col-sm-9">
                            <div className="form-check">

                            <input className="form-check-input " 
                                    checked={screen.is_active}
                                    onChange={ev => setScreen({...screen,is_active: Number(ev.target.checked)})}
                                    type="checkbox" 
                                    id="ckActive" />

                            <label className="form-check-label" htmlFor="ckActive">Active</label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="txtArName" className="col-sm-3 col-form-label">Name ( Arabic )</label>
                        <div className="col-sm-9">

                            <input type="text" 
                                value={screen.ar_name}
                                onChange={ev => setScreen({...screen,ar_name: ev.target.value})}
                                className="form-control" 
                                id="txtArName" 
                                placeholder="Arabic Name" />
                        </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="txtEnName" className="col-sm-3 col-form-label">Name ( English )</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                value={screen.en_name}
                                onChange={ev => setScreen({...screen,en_name: ev.target.value})}
                                className="form-control" 
                                id="txtEnName" 
                                placeholder="English Name" />
                        </div>
                </div>
                
                { screen.link_token && <div className="form-group row">
                        <label htmlFor="txtLinkToken" className="col-sm-3 col-form-label">Link Token</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                disabled={true}
                                value={screen.link_token}
                                className="form-control" 
                                id="txtLinkToken" />
                        </div>
                </div>}

                { screen.status > 0 && <div className="form-group row">
                        <label htmlFor="txtSerialNumber" className="col-sm-3 col-form-label">Serial Number</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                disabled={true}
                                value={screen.serial_number}
                                className="form-control" 
                                id="txtSerialNumber" />
                        </div>
                </div>}

                { screen.status > 0 && <div className="form-group row">
                        <label htmlFor="txtBrand" className="col-sm-3 col-form-label">Brand</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                disabled={true}
                                value={screen.brand}
                                className="form-control" 
                                id="txtBrand" />
                        </div>
                </div>}

                { screen.status > 0 && <div className="form-group row">
                        <label htmlFor="txtOS" className="col-sm-3 col-form-label">OS</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                disabled={true}
                                value={screen.os}
                                className="form-control" 
                                id="txtOS" />
                        </div>
                </div>}

                { screen.status > 0 && <div className="form-group row">
                        <label htmlFor="txtAspectRatio" className="col-sm-3 col-form-label">Aspect Ratio</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                disabled={true}
                                value={screen.aspect_ratio}
                                className="form-control" 
                                id="AspectRatio" />
                        </div>
                </div>}
                
                <div className="form-group row">
                        <label htmlFor="sctCompanies" className="col-sm-3 col-form-label">Branch</label>
                        <div className="col-sm-6">
                            
                            <select id="sctCompanies"
                                    // disabled={perantBranch && perantBranch.id ? true : false}
                                    value={screen.branch != null ? screen.branch.id : 0}
                                    onChange={ev => setScreen({...screen,branch: branches.find(o => o.id === Number(ev.target.value))})}
                                    className="form-control form-select form-select-lg" 
                                    aria-label="Default select example">
                                    <option value={0}>Select Branch</option>
                                    {branches && branches.map(branchRow => <option key={branchRow.id} value={branchRow.id}>{branchRow.en_name}</option>)}
                            </select>
                        </div>
                </div>


                </form>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" onClick={onSave} className="btn btn-success">{ screen && screen.id  ? "Save" : "Add"}</button>
            </div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default Screens;