import axios from "axios";

const axiosClient = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL});

axiosClient.interceptors.request.use((config)=> {
    config.headers.Authorization = `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`;


    return config;
});

axiosClient.interceptors.response.use((response)=> {

    return response;

},(error)=> {

    const {response} = error;

    if(response.status === 401)
    {
        localStorage.removeItem("ACCESS_TOKEN");
    }

    throw error;
});

export default axiosClient;