import { React } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ContextProvider } from "./Contexts/ContextProvider";



import LoginPage from "./Pages/Dashboard/Login";
import ForgotPasswordPage from "./Pages/Dashboard/ForgotPassword";
import PageNotFoundPage from "./Pages/Dashboard/PageNotFound";

import ProfilePage from "./Pages/Dashboard/Profile";
import DashboardPage from "./Pages/Dashboard/Dashboard";
import CountriesPage from "./Pages/Dashboard/Countries";
import CitiesPage from "./Pages/Dashboard/Cities";
import RolesPage from "./Pages/Dashboard/Roles";
import UsersPage from "./Pages/Dashboard/Users";

import CompaniesPage from "./Pages/Dashboard/Companies";
import BranchesPage from "./Pages/Dashboard/Branches";
import ScreensPage from "./Pages/Dashboard/Screens";
import PresentationsPage from "./Pages/Dashboard/Presentations";
import SlidesPage from ".//Pages/Dashboard/Slides";

import DashboardLayout from "./Layouts/Dashboard/DashboardLayout";
import GuestLayout from "./Layouts/Guest/GuestLayout";

function App() {
  //  const style = document.getElementById('style-direction');
  //  ("en" === 'ar') ? style.href = '/css/master_rtl.css' : style.href = '/css/master.css';


  return (
    <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<DashboardLayout />}>
            <Route path="*" element={<PageNotFoundPage />} />
            <Route path="/" element={<Navigate replace to="dashboard/" />} />

            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/dashboard/countries" element={<CountriesPage />} />
            <Route path="/dashboard/countries/:country/cities" element={<CitiesPage />} />
            <Route path="/dashboard/cities" element={<CitiesPage />} />

            <Route path="/dashboard/roles" element={<RolesPage />} />
            <Route path="/dashboard/users" element={<UsersPage />} />

            <Route path="/dashboard/companies" element={<CompaniesPage />} />
            <Route path="/dashboard/companies/:company/branches" element={<BranchesPage />} />
            <Route path="/dashboard/companies/:company/branches/:branch/screens" element={<ScreensPage />} />

            <Route path="/dashboard/branches" element={<BranchesPage />} />
            <Route path="/dashboard/branches/:branch/screens" element={<ScreensPage />} />

            <Route path="/dashboard/screens" element={<ScreensPage />} />

            <Route path="/dashboard/presentations" element={<PresentationsPage />} />
            <Route path="/dashboard/presentations/:presentation/slides" element={<SlidesPage />} />

            <Route path="/dashboard/slides" element={<SlidesPage />} />
          </Route>

          <Route element={<GuestLayout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          </Route>

        </Routes>


      </BrowserRouter>
    </ContextProvider>

  );
}

export default App;
