import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link } from "react-router-dom";
import {useMainContext} from "../../Contexts/ContextProvider";

function Companies() 
{
    const {user} = useMainContext();

    const initRowValue = {
        id:null,
        is_active:1,
        logo:"",
        ar_name:"",
        en_name:"",
        user: null,
        country:null
    };
    // const [errors,setErrors] = useState(null);

    const [countries,setCountries] =  useState();
    const [users,setUsers] =  useState();
    const [companies,setCompanies] =  useState();
    const [company,setCompany] =  useState(initRowValue);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const [tempImage,setTempImage] = useState(null);
    const btnImageSelect_Ref = useRef();
    const imgPreview_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center:true,
            width:"80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center:true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> :  <span className="badge badge-danger" >Inactive</span>,

        },
        {
            name: 'logo',
            selector: row => row.logo,
            sortable: true,
            center:true,
            cell: row => <img src={row.logo ? `${process.env.REACT_APP_STORAGE_URL}/${row.logo}` : "/img/No_Image_Available.jpg"} className="img-circle m-2 elevation-2" width="50"  alt="Company Logo" />
            
        },

        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
            
        },
        {
            name: 'Admin',
            selector: row => row.user,
            sortable: true,
            cell: row => <div>{row.user.name}</div>
            
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            cell: row => <div><span className={"fi mr-2 fi-" + row.country.domain_ext}></span><span>{row.country.en_name}</span></div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center:true,
       
        },
        {
            name: '',
            selector: row => row.actions,
            center:true,
            width:"300px",
            cell: row => <>
                            { user.branches_view !== 0 || user.branches_add !== 0 || user.branches_edit !== 0 || user.branches_delete !== 0 ? <Link state={row}  to={`${row.en_name.replace(/\s+/g, '-').toLowerCase()}/branches`} className="btn btn-warning btn-sm text-white mr-2"><i className="fa fa-code-branch"></i> Branches</Link> : "" }
                            { user.companies_edit !== 0 ?   <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : ""}  
                            { user.companies_delete !== 0 ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : ""}    
                        </>,
        },
    ];
    
    useEffect(()=>  {

        getCompanies()
        getCountries();
        getUsers();
     

    },[]);

    const getCompanies = () => {

        axiosClient.get("/companies").then((response) => {

            console.log(response.data);
            setCompanies(response.data);

           
        }).catch((error)=> {

            console.log(error);
        });

    }

    const getUsers = () => {

        axiosClient.get("/users").then((response) => {

            //console.log(response.data);
            setUsers(response.data);

           
        }).catch((error)=> {

            console.log(error);
        });

    }

    const getCountries = () => {

        axiosClient.get("/countries").then((response) => {

            //console.log(response.data);
            setCountries(response.data);

           
        }).catch((error)=> {

            console.log(error);
        });

    }

    const onView = (selectedRow) => {
        // setErrors(null);

        if(selectedRow)
        {
            setCompany(selectedRow);

            setTempImage(selectedRow.logo ? `${process.env.REACT_APP_STORAGE_URL}/${selectedRow.logo}` : "/img/No_Image_Available.jpg");
        }
        else
        {
            setCompany(initRowValue);

            setTempImage("/img/No_Image_Available.jpg");
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = (ev) => {

        // setErrors(null);

        if(company.id)
        {

            const formData = new FormData();
            formData.append("id",company.id);
            formData.append("_method","PUT");
            formData.append("is_active",company.is_active);
            formData.append("ar_name",company.ar_name);
            formData.append("en_name",company.en_name);
            formData.append("user[id]", company.user != null ?  company.user.id : null);
            formData.append("country[id]", company.country != null ?  company.country.id : null);

            if(typeof company.logo === "object")
            {
                formData.append("logo",company.logo);
            }

                axiosClient.post(`/companies/${company.id}`,formData,{ "Content-Type": "multipart/form-data"}).then((response) => {

                console.log("Response : " , response)

                setCompanies(response.data);
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${company.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
    
            }).catch((error)=> {
    
                const response = error.response;

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }
                }
                else
                {
                    console.log(response);
                }

            });
        }
        else
        {

            const formData = new FormData();
            formData.append("id",company.id);
            formData.append("is_active",company.is_active);
            formData.append("ar_name",company.ar_name);
            formData.append("en_name",company.en_name);
            formData.append("logo",company.logo);
            formData.append("user[id]", company.user != null ?  company.user.id : null);
            formData.append("country[id]", company.country != null ?  company.country.id : null);

            axiosClient.post(`/companies`,formData,{ headers: {"Content-Type": "multipart/form-data"}}).then((response) => {

                setCompanies(response.data);
                console.log("Response : " , response);

                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${company.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
            }).catch((error)=> {
    
                const response = error.response;

                console.log(response);

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }

                }
                else
                {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
          }).then((result) => {

            if (result.isConfirmed) 
            {

                axiosClient.delete(`/companies/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    setCompanies(response.data);

                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${company.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                      });

                }).catch((error) => {

                    console.log(error);
                });

            }
          })

    }

    const onImageSelect = (ev) => {

        btnImageSelect_Ref.current.click();
    }

    const onImageSelected = (ev) => {

        setCompany({ ...company, logo: ev.target.files[0]});

        if(ev.target.files.length > 0)
        {
            setTempImage(URL.createObjectURL(ev.target.files[0]));
        }

    }


  return (

    <div className="content-wrapper">
    
    <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Companies</h1>
            </div>
       
            <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                <li className="breadcrumb-item active">Companies</li>
            </ol>
            </div>
           
        </div>
        
        </div>
       
    </div>

    <div className="content">
        <div className="container-fluid">
        <div className="row">
            <div className="col-12">
            <div className="card">
                <div className="card-header text-right">
                  {user &&  user.companies_add !== 0 ?  <button onClick={ev => onView(null)}  type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Company</button> : "" }
                </div>
              
                <div className="card-body">
                    <div className="row ">
                        <div className="form-group offset-sm-10 col-sm-2 ">
                            <div className="input-group">
                            <input  type="search" className="form-control " placeholder="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-default">
                                <i className="fa fa-search" />
                                </button>
                            </div>
                            </div>

                        </div>

                    </div>

                    <DataTable className="table table-bordered" columns={columns} data={companies} pagination />

                </div>
            </div>
            
            </div>
        
        </div>
        </div>
    </div>


        <button style={{display:"none"}}  ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
        <div  className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

            <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">{company && company.id  ? "Edit Company" : "Add Company"}</h5>
                <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div className="modal-body">
            {/* {
            errors && <div className="alert alert-danger " role='alert'>
                { Object.keys(errors).map(key => (<p key={key}>{errors[key][0]}</p>))}
            </div>
            } */}

                          <div className="row">

                              <div className="col-sm-8">
                                  <form className="form-horizontal">
                                      <div className="form-group row">
                                          <div className="offset-sm-4 col-sm-9">
                                              <div className="form-check">

                                                  <input className="form-check-input"
                                                      checked={company.is_active}
                                                      onChange={ev => setCompany({ ...company, is_active: Number(ev.target.checked)})}
                                                      type="checkbox"
                                                      id="ckActive" />

                                                  <label className="form-check-label" htmlFor="ckActive">Active</label>
                                              </div>
                                          </div>
                                      </div>

                                      <div className="form-group row">
                                          <label htmlFor="txtArName" className="col-sm-4 col-form-label">Name ( Arabic )</label>
                                          <div className="col-sm-8">

                                              <input type="text"
                                                  value={company.ar_name}
                                                  onChange={ev => setCompany({ ...company, ar_name: ev.target.value })}
                                                  className="form-control"
                                                  id="txtArName"
                                                  placeholder="Arabic Name" />
                                          </div>
                                      </div>

                                      <div className="form-group row">
                                          <label htmlFor="txtEnName" className="col-sm-4 col-form-label">Name ( English )</label>
                                          <div className="col-sm-8">
                                              <input type="text"
                                                  value={company.en_name}
                                                  onChange={ev => setCompany({ ...company, en_name: ev.target.value })}
                                                  className="form-control"
                                                  id="txtEnName"
                                                  placeholder="English Name" />
                                          </div>
                                      </div>

                                      <div className="form-group row">
                                          <label htmlFor="sctUsers" className="col-sm-4 col-form-label">Admin</label>
                                          <div className="col-sm-8">

                                              <select id="sctUsers"
                                                  value={company.user ? company.user.id : 0}
                                                  onChange={ev => setCompany({ ...company, user: users.find(o => o.id === Number(ev.target.value)) })}
                                                  className="form-control form-select form-select-lg"
                                                  aria-label="Default select example">
                                                  <option value={0}>Select User</option>
                                                  {users && users.map(userRow => <option key={userRow.id} value={userRow.id}>{userRow.name}</option>)}
                                              </select>
                                          </div>
                                      </div>

                                      

                                      <div className="form-group row">
                                          <label htmlFor="sctCountries" className="col-sm-4 col-form-label">Country</label>
                                          <div className="col-sm-8">

                                              <select id="sctCountries"
                                                  value={company.country ? company.country.id : 0}
                                                  onChange={ev => setCompany({ ...company, country: countries.find(o => o.id === Number(ev.target.value)) })}
                                                  className="form-control form-select form-select-lg"
                                                  aria-label="Default select example">
                                                  <option value={0}>Select Country</option>
                                                  {countries && countries.map(countryRow => <option key={countryRow.id} value={countryRow.id}>{countryRow.en_name}</option>)}
                                              </select>
                                          </div>
                                      </div>

                                  </form>

                              </div>

                              <div className="col-sm-4">
                                <h6 className="col-sm-12 text-center">Company Logo</h6>
                                <img ref={imgPreview_Ref} 
                                     className="col-sm-12 img-fluid img-thumbnail" 
                                     src={tempImage} 
                                     alt="Company Logo" 
                                     />
                                <button type="button" onClick={onImageSelect} className="btn btn-success col-sm-12 mt-2">{ company && company.logo  ? "Change" : "Select"}</button>
                                <input ref={btnImageSelect_Ref} style={{display:"none"}} type="file" onChange={onImageSelected} />
                                
                              </div>
                          </div>

            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" onClick={onSave} className="btn btn-success">{ company && company.id  ? "Save" : "Add"}</button>
            </div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default Companies;