import React from 'react'

function PageNotFound() 
{
  return (
    <div className="content-wrapper">
      <h1 className="text-center">Page Not Found.</h1>
  </div>
  )
}

export default PageNotFound