import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useMainContext} from "../../Contexts/ContextProvider";
import 'react-tabs/style/react-tabs.css';

function Presentations() 
{
    const {user} = useMainContext();

    const initRowValues = {
        id: null,
        is_active: 1,
        ar_name: "",
        en_name: "",
        start_at: null,
        duration_type: 0,
        duration_value: 0,
        company: null,
        screens:null
    };

    // const [errors, setErrors] = useState(null);
    const [companies,setCompanies] =  useState();
    const [presentations, setPresentations] = useState();
    const [presentation, setPresentation] = useState(initRowValues);

    const [screens, setScreens] = useState();
    const [screensFilteredData, setScreensFilterdData] = useState();

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,
        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.start_at,
            sortable: true,
            center: true,
        },
        {
            name: 'Duration',
            selector: row => row.duration_type,
            sortable: true,
            center: true,
            cell: row => {

                var duration="";
                switch(Number(row.duration_type))
                {
                    case 0:
                        duration = <span>Always</span>
                    break;

                    case 1:
                        duration = <span>{row.duration_value} Hour</span>
                    break;

                    case 2:
                        duration = <span>{row.duration_value} Day</span>
                    break;

                    case 3:
                        duration = <span>{row.duration_value} Month</span>
                    break;

                    case 4:
                        duration = <span>{row.duration_value} Year</span>
                    break;

                    default:
                        duration = <span>{row.duration_value} Unknow</span>

                }

                return duration;
            
            }
        },
        {
            name: 'Company',
            selector: row => row.company.en_name,
            sortable: true,
            cell: row => <div>
                            <img src={row.company.logo ? `${process.env.REACT_APP_STORAGE_URL}/${row.company.logo}` : "/img/No_Image_Available.jpg"} className="img-circle m-2 elevation-2" width="40" alt="Company Logo" />
                            <span>{row.company.en_name}</span>
                        </div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,
        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                 {user.slides_view !== 0 || user.slides_add !== 0 || user.slides_edit !== 0 || user.slides_delete !== 0 ? <Link state={row}  to={`${row.en_name.replace(/\s+/g, '-').toLowerCase()}/slides`} className="btn btn-warning btn-sm text-white mr-2"><i className="fa fa-photo-video"></i> Slides</Link> : "" }  
                { user.shared_presentations_edit !== 0 ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                { user.shared_presentations_delete !== 0 ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
            </>,
        },
    ];

    const screens_columns = [
        {
            name: 'Name',
            selector: row => row.en_name,
            center:true,
            sortable: true,
        },
        {
            name: 'Branch',
            selector: row => row.branch.en_name,
            center:true,
            sortable: true,
        },
        {
            name: 'Company',
            selector: row => row.branch.company.en_name,
            center:true,
            sortable: true,
            cell: row => <img src={row.branch.company.logo ? `${process.env.REACT_APP_STORAGE_URL}/${row.branch.company.logo}` : "/img/No_Image_Available.jpg"} className="img-circle m-2 elevation-2" width="40" alt="Company Logo" />

            
        },
    ];

    useEffect(() => {
        getPresentations();
        getCompanies();
        getScreens();
       
    }, []);

    const getPresentations = () => {

        axiosClient.get("/presentations").then((response) => {

            console.log(response.data);
            setPresentations(response.data);
          

        }).catch((error) => {

            console.log(error);
        });
    }

    const getScreens = () => {

        axiosClient.get(`/screens`).then((response) => {

            console.log(response.data);
            setScreens(response.data);
            setScreensFilterdData(response.data)
        

        }).catch((error) => {

            console.log(error);

        });
    }

    const getCompanies = () => {

        axiosClient.get("/companies").then((response) => {

            //console.log(response.data);
            setCompanies(response.data);
           
        }).catch((error)=> {

            console.log(error);
           
        });

    }

    const onView = (selectedRow) => {
        // setErrors(null);

        if (selectedRow) 
        {
            setPresentation(selectedRow);
        }
        else 
        {
            setPresentation(initRowValues);
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = () => {
        // setErrors(null);

        if (presentation.id) {
            console.log("Edit",presentation);

            axiosClient.put(`/presentations/${presentation.id}`,presentation).then((response) => {

                console.log("Response : ", response)
                getPresentations();

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${presentation.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                const response = error.response;

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }
                }
                else {
                    console.log(response);
                }

            });
        }
        else {
            console.log("Add",presentation);

            axiosClient.post(`/presentations`,presentation).then((response) => {

                console.log("Response : ", response);
                getPresentations();

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${presentation.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                const response = error.response;

                console.log(response);

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }

                }
                else {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/presentations/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    getPresentations();


                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${presentation.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    console.log(error);
                });

            }
        })

    }

    const screens_onSearch = (ev) => 
    {
       const data = screens.filter((row) => { 

        if(row.en_name.toLowerCase().includes(ev.target.value.toLowerCase()) ||
           row.ar_name.toLowerCase().includes(ev.target.value.toLowerCase()) || 
           row.branch.en_name.toLowerCase().includes(ev.target.value.toLowerCase()) || 
           row.branch.ar_name.toLowerCase().includes(ev.target.value.toLowerCase()) || 
           row.branch.company.en_name.toLowerCase().includes(ev.target.value.toLowerCase()) || 
           row.branch.company.ar_name.toLowerCase().includes(ev.target.value.toLowerCase()))
        {
            return row;
        }
        else
        {
            return null;
        }
        // return (row.en_name.toLowerCase().includes(ev.target.value.toLowerCase())) ? row : null
    });

        setScreensFilterdData(data);
    }

    const screens_setSelectedRows = (r) => {
    
        if(presentation.screens != null) return (presentation.screens.find(o => o.id === r.id)) ? true : false;
    }

    const screens_onSelectedRowsChange = (rows) =>
    {
         //if(rows.selectedCount === 0 ) { return }
         if(presentation.screens && presentation.screens.length  === rows.selectedCount) { return }

         console.log("SelectedRows",rows.selectedRows);

         setPresentation({ ...presentation, screens: rows.selectedRows })
    }

    return (
        <div className="content-wrapper">

        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0"> </h1>
                    </div>

                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                            <li className="breadcrumb-item active">Presentations</li>
                        </ol>
                    </div>

                </div>

            </div>

        </div>

        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header text-right">
                            {user &&  user.presentations_add !== 0 ?  <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Presentation</button> : "" }
                            </div>

                            <div className="card-body">
                                <div className="row ">
                                    <div className="form-group offset-sm-10 col-sm-2 ">
                                        <div className="input-group">
                                            <input type="search" className="form-control " placeholder="Search" />
                                            <div className="input-group-append">
                                                <button className="btn btn-default">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <DataTable className="table table-bordered" columns={columns} data={presentations} pagination />

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
        <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="exampleModalLabel">{presentation && presentation.id ? "Edit Presentation" : "Add Presentation"}</h5>
                        <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        {/* {
                            errors && <div className="alert alert-danger " role='alert'>
                                {
                                    Object.keys(errors).map(key => (
                                        <p key={key}>{errors[key][0]}</p>
                                    ))
                                }
                            </div>
                        } */}

                        <Tabs>
                            <TabList>
                                <Tab>General</Tab>
                                <Tab>Screens</Tab>
                            </TabList>

                            <TabPanel>
                            <form className="form-horizontal">
                                    <div className="form-group row">
                                        <div className="offset-sm-3 col-sm-9">
                                            <div className="form-check">

                                                <input className="form-check-input "
                                                    checked={presentation.is_active}
                                                    onChange={ev => setPresentation({ ...presentation, is_active: Number(ev.target.checked) })}
                                                    type="checkbox"
                                                    id="ckActive" />

                                                <label className="form-check-label" htmlFor="ckActive">Active</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="txtArName" className="col-sm-3 col-form-label">Name ( Arabic )</label>
                                        <div className="col-sm-9">

                                            <input type="text"
                                                value={presentation.ar_name}
                                                onChange={ev => setPresentation({ ...presentation, ar_name: ev.target.value })}
                                                className="form-control"
                                                id="txtArName"
                                                placeholder="Arabic Name" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="txtEnName" className="col-sm-3 col-form-label">Name ( English )</label>
                                        <div className="col-sm-9">
                                            <input type="text"
                                                value={presentation.en_name}
                                                onChange={ev => setPresentation({ ...presentation, en_name: ev.target.value })}
                                                className="form-control"
                                                id="txtEnName"
                                                placeholder="English Name" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="txtStartAt" className="col-sm-3 col-form-label">Start Date</label>
                                        <div className="col-sm-9">
                                            <input type="datetime-local"
                                                value={presentation.start_at != null && presentation.start_at}
                                                onChange={ev => setPresentation({ ...presentation, start_at: ev.target.value })}
                                                className="form-control"
                                                id="txtStartAt"
                                                placeholder="Start at" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="txtPresentationDurationValue" className="col-sm-3 col-form-label"> Duration</label>

                                        <div className="col-sm-6">
                                            <input type="text"
                                                value={presentation.duration_value}
                                                onChange={ev => setPresentation({ ...presentation, duration_value: ev.target.value })}
                                                className="form-control"
                                                id="txtPresentationDurationValue"
                                                placeholder="Duration Value" />
                                        </div>

                                        <div className="col-sm-3">
                                            <select id="sctPresentationDurationType"
                                                value={presentation.duration_type}
                                                onChange={ev => setPresentation({ ...presentation,duration_type: ev.target.value })}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>Always</option>
                                                <option value={1}>Hour</option>
                                                <option value={2}>Day</option>
                                                <option value={3}>Month</option>
                                                <option value={4}>Year</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="sctCompanies" className="col-sm-3 col-form-label">Company</label>
                                        <div className="col-sm-6">

                                            <select id="sctCompanies"
                                                value={presentation.company ? presentation.company.id : 0}
                                                onChange={ev => setPresentation({ ...presentation, company: companies.find(o => o.id === Number(ev.target.value))})}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>Select Company</option>
                                                {companies && companies.map(companyRow => <option key={companyRow.id} value={companyRow.id}>{companyRow.en_name}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                </form>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="row ">
                                    <div className="form-group col-sm-12 ">
                                        <div className="input-group">
                                            <input onChange={screens_onSearch} type="search" className="form-control " placeholder="Search" />
                                            <div className="input-group-append">
                                                <button className="btn btn-default">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <DataTable className="table table-bordered"
                                    columns={screens_columns}
                                    data={screensFilteredData}
                                    selectableRows
                                    selectableRowSelected={screens_setSelectedRows}
                                    onSelectedRowsChange={screens_onSelectedRowsChange}
                                    pagination

                                />

                            </TabPanel> 

                        </Tabs>


                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" onClick={onSave} className="btn btn-success">{presentation && presentation.id ? "Save" : "Add"}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
}

export default Presentations;