import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useMainContext } from "../../../Contexts/ContextProvider"
import axiosClient from "../../../Helpers/Axios-Client";

function SideMenu() {

  const { user } = useMainContext();
  const [companies, setCompanies] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {

    getSelectedCompany();
    getMyCompanies()

  }, []);

  const getSelectedCompany = () => {

    axiosClient.get("/misc/get-selected-company").then((response) => {

     user.role === null && console.log("getSelectedCompany",response.data);
      setSelectedCompany(response.data);

    }).catch((error) => {

      console.log(error);
    });

  }



  const changeSelectedCompany = (companyRow) => {

    if(Number(selectedCompany.id) === Number(companyRow.id)) { return }
    //console.log(CompanyId);

    axiosClient.post(`/misc/set-selected-company`,{ company_id: companyRow.id }).then((response) => {
    // console.log("ChangeSelectedCompany",response.data);
      window.location.reload();

    }).catch((error) => {

      console.log(error);
    });



  }

  const getMyCompanies = () => {

    axiosClient.get("/misc/get-my-companies").then((response) => {

      console.log(response.data);
      setCompanies(response.data);

    }).catch((error) => {

      console.log(error);
    });

  }

  return (
    <aside className="main-sidebar sidebar-light-success">
      {/* Brand Logo */}

      <Link to={"/"} className="brand-link text-center" >
        <img src="/img/Escreen_Logo_Blue.png" alt="Logo" className="img-fluid" style={{ width: "70%" }} />
      </Link>

      {/* Sidebar */}
      <div className="sidebar">
        {user.role != null &&
          <div className="dropdown companies-panel align-middle">
            {selectedCompany &&
              <div className="user-panel mt-3 mb-3 pt-3 pb-3 dropdown-toggle" data-toggle="dropdown">
                <div className="image">
                  <img src={selectedCompany.logo ? `${process.env.REACT_APP_STORAGE_URL}/${selectedCompany.logo}` : "/img/No_Image_Available.jpg"} className="img-circle elevation-2" alt="Company Logo" />
                </div>

                <div className="info align-middle">
                  <div className="d-block">{selectedCompany.en_name}</div>
                </div>
              </div>}


            <div className="dropdown-menu pt-0 pb-0">
            {companies && companies.map(companyRow => { 
              // let isActive = companyRow.id === selectedCompany.id ? "active" : "";
              // ${isActive }
              return <div className={`dropdown-item`} key={companyRow.id} value={companyRow.id} onClick={ev => changeSelectedCompany(companyRow)}>
                <div className="user-panel d-flex">
                  <div className="image">
                    <img src={companyRow.logo ? `${process.env.REACT_APP_STORAGE_URL}/${companyRow.logo}` : "/img/No_Image_Available.jpg"} className="img-circle elevation-2" alt="Company Logo" />
                  </div>

                  <div className="info">
                    <span className="d-block">{companyRow.en_name}</span>
                  </div>

                </div>
              </div>
            }
              )}

            </div>
          </div>}

        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            <li className="nav-header">Analytics</li>
            <li className="nav-item">
              <NavLink to={"/dashboard/"} className="nav-link" ><i className="nav-icon fas fa-tachometer-alt" /><p>Dashboard</p></NavLink>
            </li>

            {(user.countries_view !== 0 || user.countries_add !== 0 || user.countries_edit !== 0 || user.countries_delete !== 0 ||
              user.cities_view !== 0 || user.cities_add !== 0 || user.cities_edit !== 0 || user.cities_delete !== 0 ||
              user.roles_view !== 0 || user.roles_add !== 0 || user.roles_edit !== 0 || user.roles_delete !== 0 ||
              user.users_view !== 0 || user.users_add !== 0 || user.users_edit !== 0 || user.users_delete !== 0) ?

              <li className="nav-header">Administration</li>
              : null}


            {(user.countries_view !== 0 || user.countries_add !== 0 || user.countries_edit !== 0 || user.countries_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/countries"} className="nav-link"><i className="nav-icon fas fa-flag" /><p>Countries</p></NavLink>
              </li>
              : null}

            {(user.cities_view !== 0 || user.cities_add !== 0 || user.cities_edit !== 0 || user.cities_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/cities"} className="nav-link"><i className="nav-icon fas fa-city" /><p>Cities</p></NavLink>
              </li>
              : null}

            {(user.roles_view !== 0 || user.roles_add !== 0 || user.roles_edit !== 0 || user.roles_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/roles"} className="nav-link"><i className="nav-icon fas fa-users-cog" /><p>Roles</p></NavLink>
              </li>
              : null}

            {(user.users_view !== 0 || user.users_add !== 0 || user.users_edit !== 0 || user.users_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/users"} className="nav-link"><i className="nav-icon fas fa-users" /><p>Users</p></NavLink>
              </li>
              : null}

            <li className="nav-header">Data</li>
            {(user.companies_view !== 0 || user.companies_add !== 0 || user.companies_edit !== 0 || user.companies_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/companies"} className="nav-link"><i className="nav-icon fas fa-building" /><p>Companies</p></NavLink>
              </li>
              : null}

            {(user.branches_view !== 0 || user.branches_add !== 0 || user.branches_edit !== 0 || user.branches_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/branches"} className="nav-link"><i className="nav-icon fas fa-code-branch" /><p>Branches</p></NavLink>
              </li>
              : null}

            {(user.screens_view !== 0 || user.screens_add !== 0 || user.screens_edit !== 0 || user.screens_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/screens"} className="nav-link"><i className="nav-icon fas fa-tv" /><p>Screens</p></NavLink>
              </li>
              : null}

            <li className="nav-header">Operations</li>
            {(user.presentations_view !== 0 || user.presentations_add !== 0 || user.presentations_edit !== 0 || user.presentations_delete !== 0 ||
              user.slides_view !== 0 || user.slides_add !== 0 || user.slides_edit !== 0 || user.slides_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/presentations"} className="nav-link"><i className="nav-icon fas fa-film" /><p>Presentations</p></NavLink>
              </li>
              : null}

            {(user.slides_view !== 0 || user.slides_add !== 0 || user.slides_edit !== 0 || user.slides_delete !== 0) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/slides"} className="nav-link"><i className="nav-icon fas fa-photo-video" /><p>Slides</p></NavLink>
              </li>
              : null}

          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>

  );
}

export default SideMenu;
