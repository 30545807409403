import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useMainContext} from "../../Contexts/ContextProvider";
import 'react-tabs/style/react-tabs.css';

function Users() {

    const {user} = useMainContext();

    const emptyRow = {
        id: null,
        is_active: 1,
        role: null,
        name: "",
        img: "",
        mobile: "",
        email: "",
        password: "",
        country: null,
        // selected_company: null,
        selected_lang_id: 0,

        countries_view:0,
        countries_add:0,
        countries_edit:0,
        countries_delete:0,

        cities_view:0,
        cities_add:0,
        cities_edit:0,
        cities_delete:0,

        roles_view:0,
        roles_add:0,
        roles_edit:0,
        roles_delete:0,

        users_view:0,
        users_add:0,
        users_edit:0,
        users_delete:0,

        companies_view:0,
        companies_add:0,
        companies_edit:0,
        companies_delete:0,

        branches_view:0,
        branches_add:0,
        branches_edit:0,
        branches_delete:0,

        screens_view:0,
        screens_add:0,
        screens_edit:0,
        screens_delete:0,

        presentations_view:0,
        presentations_add:0,
        presentations_edit:0,
        presentations_delete:0,

        slides_view:0,
        slides_add:0,
        slides_edit:0,
        slides_delete:0,
    };

    // const [errors, setErrors] = useState();

    // const [companies,setCompanies] =  useState();
    const [countries, setCountries] = useState();
    const [roles, setRoles] = useState();

    const [users, setUsers] = useState();
    const [cuser, setCUser] = useState(emptyRow);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const [tempImage, setTempImage] = useState(null);
    const btnImageSelect_Ref = useRef();
    const imgPreview_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            width: "90px",
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,

        },
        {
            name: 'Image',
            selector: row => row.img,
            sortable: true,
            width: "90px",
            cell: row => <img src={row.img ? `${process.env.REACT_APP_STORAGE_URL}/${row.img}` : "/img/No_Image_Available.jpg"} className="img-circle m-2 elevation-2" width="50" alt="User Logo" />

        },

        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
            center: true,
            width: "80px",
            cell: row => <b>{row.role.en_name}</b>
        },

        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "150px",

        },
        {
            name: 'EMail',
            selector: row => row.email,
            sortable: true,
            center: true,
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            width: "150px",
            cell: row => <div><i className={`fi mr-2 fi-${row.country.domain_ext}`} /><span>{row.country.en_name}</span></div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,
            width: "140px",

        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                { user && user.users_edit !== 0 ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                { user && user.users_delete !== 0 ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
            </>,
        },
    ];

    useEffect(() => {

        getUsers();
        getCountries();
        getRoles();
        // getCompanies();
        
    }, []);

    const getUsers = () => {

        axiosClient.get("/users").then((response) => {

            // console.log(response.data);
            setUsers(response.data);


        }).catch((error) => {

            console.log(error);
        });

    }

    const getCountries = () => {

        axiosClient.get("/countries").then((response) => {

            // console.log(response.data);
            setCountries(response.data);
            



        }).catch((error) => {

            console.log(error);
        });

    }

    const getRoles = () => {

        axiosClient.get("/roles").then((response) => {
            // console.log(response.data);
            setRoles(response.data);

        }).catch((error) => {

            console.log(error);
        });

    }

    // const getCompanies = () => {

    //     axiosClient.get("/companies").then((response) => {

    //         console.log(response.data);
    //         setCompanies(response.data);
        
           
    //     }).catch((error)=> {

    //         console.log(error);
           
    //     });

    // }

    const onView = (selectedRow) => {
        // setErrors(null);

        if (selectedRow) {
            setCUser(selectedRow);

            const currentImage = selectedRow.img ? `${process.env.REACT_APP_STORAGE_URL}/${selectedRow.img}` : "/img/No_Image_Available.jpg";
            setTempImage(currentImage);
        }
        else {
            setCUser(emptyRow);

            setTempImage("/img/No_Image_Available.jpg");
        }

        openDetailsModal_Ref.current.click();

    }

    const onSave = () => {
        // setErrors(null);

        if (cuser.id) {
            console.log("Edit");

            const formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("id", cuser.id);
            formData.append("is_active", cuser.is_active);
            formData.append("role[id]",  cuser.role != null ? cuser.role.id : null);
            formData.append("country[id]",cuser.country != null ? cuser.country.id : null);
            // formData.append("selected_company[id]", cuser.selected_company != null ? cuser.selected_company.id : null);
            formData.append("name", cuser.name);
            formData.append("mobile", cuser.mobile);
            formData.append("email", cuser.email);
            formData.append("password", cuser.password);
            formData.append("selected_lang_id", cuser.selected_lang_id);
 
            formData.append("countries_view", cuser.countries_view);
            formData.append("countries_add", cuser.countries_add);
            formData.append("countries_edit", cuser.countries_edit);
            formData.append("countries_delete", cuser.countries_delete);

            formData.append("cities_view", cuser.cities_view);
            formData.append("cities_add", cuser.cities_add);
            formData.append("cities_edit", cuser.cities_edit);
            formData.append("cities_delete", cuser.cities_delete);

            formData.append("roles_view", cuser.roles_view);
            formData.append("roles_add", cuser.roles_add);
            formData.append("roles_edit", cuser.roles_edit);
            formData.append("roles_delete", cuser.roles_delete);

            formData.append("users_view", cuser.users_view);
            formData.append("users_add", cuser.users_add);
            formData.append("users_edit", cuser.users_edit);
            formData.append("users_delete", cuser.users_delete);

            formData.append("companies_view", cuser.companies_view);
            formData.append("companies_add", cuser.companies_add);
            formData.append("companies_edit", cuser.companies_edit);
            formData.append("companies_delete", cuser.companies_delete);

            formData.append("branches_view", cuser.branches_view);
            formData.append("branches_add", cuser.branches_add);
            formData.append("branches_edit", cuser.branches_edit);
            formData.append("branches_delete", cuser.branches_delete);

            formData.append("screens_view", cuser.screens_view);
            formData.append("screens_add", cuser.screens_add);
            formData.append("screens_edit", cuser.screens_edit);
            formData.append("screens_delete", cuser.screens_delete);

            formData.append("presentations_view", cuser.presentations_view);
            formData.append("presentations_add", cuser.presentations_add);
            formData.append("presentations_edit", cuser.presentations_edit);
            formData.append("presentations_delete", cuser.presentations_delete);

            formData.append("slides_view", cuser.slides_view);
            formData.append("slides_add", cuser.slides_add);
            formData.append("slides_edit", cuser.slides_edit);
            formData.append("slides_delete", cuser.slides_delete);

            if (typeof cuser.img === "object") {
                formData.append("img", cuser.img);
            }


            axiosClient.post(`/users/${cuser.id}`, formData, { headers: { "Content-Type": "multipart/form-data" }, }).then((response) => {

                console.log("Response : ", response)

                setUsers(response.data);
                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${cuser.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                const response = error.response;

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }
                }
                else {
                    console.log(response);
                }

            });
        }
        else {
            console.log("Add");
            console.log(cuser);

            const formData = new FormData();
            formData.append("id", cuser.id);
            formData.append("is_active", cuser.is_active);
            formData.append("role[id]",  cuser.role != null ? cuser.role.id : null);
            formData.append("country[id]",cuser.country != null ? cuser.country.id : null);
            // formData.append("selected_company[id]", cuser.selected_company != null ? cuser.selected_company.id : null);
            formData.append("name", cuser.name);
            formData.append("mobile", cuser.mobile);
            formData.append("email", cuser.email);
            formData.append("password", cuser.password);
            formData.append("selected_lang_id", cuser.selected_lang_id);

            formData.append("countries_view", cuser.countries_view);
            formData.append("countries_add", cuser.countries_add);
            formData.append("countries_edit", cuser.countries_edit);
            formData.append("countries_delete", cuser.countries_delete);

            formData.append("cities_view", cuser.cities_view);
            formData.append("cities_add", cuser.cities_add);
            formData.append("cities_edit", cuser.cities_edit);
            formData.append("cities_delete", cuser.cities_delete);

            formData.append("roles_view", cuser.roles_view);
            formData.append("roles_add", cuser.roles_add);
            formData.append("roles_edit", cuser.roles_edit);
            formData.append("roles_delete", cuser.roles_delete);

            formData.append("users_view", cuser.users_view);
            formData.append("users_add", cuser.users_add);
            formData.append("users_edit", cuser.users_edit);
            formData.append("users_delete", cuser.users_delete);

            formData.append("companies_view", cuser.companies_view);
            formData.append("companies_add", cuser.companies_add);
            formData.append("companies_edit", cuser.companies_edit);
            formData.append("companies_delete", cuser.companies_delete);

            formData.append("branches_view", cuser.branches_view);
            formData.append("branches_add", cuser.branches_add);
            formData.append("branches_edit", cuser.branches_edit);
            formData.append("branches_delete", cuser.branches_delete);

            formData.append("screens_view", cuser.screens_view);
            formData.append("screens_add", cuser.screens_add);
            formData.append("screens_edit", cuser.screens_edit);
            formData.append("screens_delete", cuser.screens_delete);

            formData.append("presentations_view", cuser.presentations_view);
            formData.append("presentations_add", cuser.presentations_add);
            formData.append("presentations_edit", cuser.presentations_edit);
            formData.append("presentations_delete", cuser.presentations_delete);

            formData.append("slides_view", cuser.slides_view);
            formData.append("slides_add", cuser.slides_add);
            formData.append("slides_edit", cuser.slides_edit);
            formData.append("slides_delete", cuser.slides_delete);

            if (typeof cuser.img === "object") {
                formData.append("img", cuser.img);
            }


            axiosClient.post(`/users`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((response) => {

                setUsers(response.data);
                console.log("Response : ", response);

                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${cuser.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                const response = error.response;

                console.log(response);

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }
                }
                else {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/users/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    setUsers(response.data);


                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${cuser.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    console.log(error);
                });

            }
        })

    }

    const onImageSelect = (ev) => {

        btnImageSelect_Ref.current.click();
    }

    const onImageSelected = (ev) => {

        setCUser({ ...cuser, img: ev.target.files[0] });

        if (ev.target.files.length > 0) {
            setTempImage(URL.createObjectURL(ev.target.files[0]));
        }

    }

    const onRoleChange = (ev) => {

        if(Number(ev.target.value) > 0)
        {
            const roleRow = roles && roles.find(o => o.id === Number(ev.target.value) );

            const newRow = {
                id: cuser.id,
                is_active: 1,
                role:roleRow,
                name: cuser.name,
                img: cuser.img,
                mobile: cuser.mobile,
                email: cuser.email,
                password: cuser.password,
                country: cuser.country,
                // selected_company: cuser.selected_company,
                selected_lang_id: cuser.selected_lang_id,

                countries_view:roleRow.countries_view,
                countries_add:roleRow.countries_add,
                countries_edit:roleRow.countries_edit,
                countries_delete:roleRow.countries_delete,
        
                cities_view:roleRow.cities_view,
                cities_add:roleRow.cities_add,
                cities_edit:roleRow.cities_edit,
                cities_delete:roleRow.cities_delete,
        
                roles_view:roleRow.roles_view,
                roles_add:roleRow.roles_add,
                roles_edit:roleRow.roles_edit,
                roles_delete:roleRow.roles_delete,
        
                users_view:roleRow.users_view,
                users_add:roleRow.users_add,
                users_edit:roleRow.users_edit,
                users_delete:roleRow.users_delete,
        
                companies_view:roleRow.companies_view,
                companies_add:roleRow.companies_add,
                companies_edit:roleRow.companies_edit,
                companies_delete:roleRow.companies_delete,
        
                branches_view:roleRow.branches_view,
                branches_add:roleRow.branches_add,
                branches_edit:roleRow.branches_edit,
                branches_delete:roleRow.branches_delete,
        
                screens_view:roleRow.screens_view,
                screens_add:roleRow.screens_add,
                screens_edit:roleRow.screens_edit,
                screens_delete:roleRow.screens_delete,
        
                presentations_view:roleRow.presentations_view,
                presentations_add:roleRow.presentations_add,
                presentations_edit:roleRow.presentations_edit,
                presentations_delete:roleRow.presentations_delete,

                slides_view:roleRow.slides_view,
                slides_add:roleRow.slides_add,
                slides_edit:roleRow.slides_edit,
                slides_delete:roleRow.slides_delete,
            };

            setCUser(newRow);
        }
        else
        {
            const newRow = {
                id: cuser.id,
                is_active: 1,
                role:null,
                name: cuser.name,
                img: cuser.img,
                mobile: cuser.mobile,
                email: cuser.email,
                password: cuser.password,
                country: cuser.country,
                // selected_company: cuser.selected_company,
                selected_lang_id: cuser.selected_lang_id,

                countries_view:0,
                countries_add:0,
                countries_edit:0,
                countries_delete:0,
        
                cities_view:0,
                cities_add:0,
                cities_edit:0,
                cities_delete:0,
        
                roles_view:0,
                roles_add:0,
                roles_edit:0,
                roles_delete:0,
        
                users_view:0,
                users_add:0,
                users_edit:0,
                users_delete:0,
        
                companies_view:0,
                companies_add:0,
                companies_edit:0,
                companies_delete:0,
        
                branches_view:0,
                branches_add:0,
                branches_edit:0,
                branches_delete:0,
        
                screens_view:0,
                screens_add:0,
                screens_edit:0,
                screens_delete:0,
        
                presentations_view:0,
                presentations_add:0,
                presentations_edit:0,
                presentations_delete:0,

                slides_view:0,
                slides_add:0,
                slides_edit:0,
                slides_delete:0,
        
            };

            setCUser(newRow);
        }

    }

    return (
        <div className="content-wrapper">

        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Users</h1>
                    </div>

                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                            <li className="breadcrumb-item active">Users</li>
                        </ol>
                    </div>

                </div>

            </div>

        </div>

        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header text-right">
                            {user && user.users_add !== 0 ?   <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New User</button> : "" }
                            </div>

                            <div className="card-body">
                                <div className="row ">
                                    <div className="form-group offset-sm-10 col-sm-2 ">
                                        <div className="input-group">
                                            <input type="search" className="form-control " placeholder="Search" />
                                            <div className="input-group-append">
                                                <button className="btn btn-default">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <DataTable
                                    className="table table-bordered"
                                    columns={columns}
                                    data={users}
                                    pagination
                                />

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
        <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="exampleModalLabel">{cuser && cuser.id ? "Edit User" : "Add User"}</h5>
                        <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        {/* {
                            errors && <div className="alert alert-danger " role='alert'>
                                {
                                    Object.keys(errors).map(key => (
                                        <p key={key}>{errors[key][0]}</p>
                                    ))
                                }
                            </div>
                        } */}




                        <Tabs>
                            <TabList>
                                <Tab>General</Tab>
                                <Tab>Permissions</Tab>
                            </TabList>

                            <TabPanel>
                            <div className="row">
                            <div className="col-sm-8">
                                <form className="form-horizontal">
                                    {/* Is Active */}
                                    <div className="form-group row">
                                        <div className="offset-sm-4 col-sm-9">
                                            <div className="form-check">

                                                <input className="form-check-input"
                                                    checked={cuser.is_active}
                                                    onChange={ev => setCUser({ ...cuser, is_active: Number(ev.target.checked) })}
                                                    type="checkbox"
                                                    id="ckActive" />

                                                <label className="form-check-label" htmlFor="ckActive">Active</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* User Role */}
                                    <div className="form-group row">
                                        <label htmlFor="sctRoles" className="col-sm-4 col-form-label">Role</label>
                                        <div className="col-sm-8">

                                            <select id="sctRoles"
                                                value={ cuser.role != null ? cuser.role.id : 0}
                                                onChange={onRoleChange}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>Select Role</option>
                                                {roles && roles.map(roleRow => <option key={roleRow.id} value={roleRow.id}>{roleRow.en_name}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Name */}
                                    <div className="form-group row">
                                        <label htmlFor="txtName" className="col-sm-4 col-form-label">Name</label>
                                        <div className="col-sm-8">

                                            <input type="text"
                                                value={cuser.name}
                                                onChange={ev => setCUser({ ...cuser, name: ev.target.value })}
                                                className="form-control"
                                                id="txtName"
                                                placeholder="Full Name" />
                                        </div>
                                    </div>

                                    {/* EMail */}
                                    <div className="form-group row">
                                        <label htmlFor="txtEMail" className="col-sm-4 col-form-label">EMail</label>
                                        <div className="col-sm-8">
                                            <input type="email"
                                                value={cuser.email}
                                                onChange={ev => setCUser({ ...cuser, email: ev.target.value })}
                                                className="form-control"
                                                id="txtEMail"
                                                placeholder="EMail" />
                                        </div>
                                    </div>

                                    {/* Mobile */}
                                    <div className="form-group row">
                                        <label htmlFor="txtMobile" className="col-sm-4 col-form-label">Mobile</label>
                                        <div className="col-sm-8">
                                            <input type="phone"
                                                value={cuser.mobile}
                                                onChange={ev => setCUser({ ...cuser, mobile: ev.target.value })}
                                                className="form-control"
                                                id="txtMobile"
                                                placeholder="Mobile" />
                                        </div>
                                    </div>

                                    {/* Password */}
                                    <div className="form-group row">
                                        <label htmlFor="txtPassword" className="col-sm-4 col-form-label">Password</label>
                                        <div className="col-sm-8">
                                            <input type="password"
                                                value={cuser.password}
                                                onChange={ev => setCUser({ ...cuser, password: ev.target.value })}
                                                className="form-control"
                                                id="txtPassword"
                                                placeholder="Password"
                                                autoComplete="true"
                                            />
                                        </div>
                                    </div>

                                    {/* Select Country */}
                                    <div className="form-group row">
                                        <label htmlFor="sctCountries" className="col-sm-4 col-form-label">Country</label>
                                        <div className="col-sm-8">

                                            <select id="sctCountries"
                                                value={cuser.country != null ? cuser.country.id : 0}
                                                onChange={ev => setCUser({ ...cuser, country: countries.find(o => o.id === Number(ev.target.value)) })}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>Select Country</option>
                                                {countries && countries.map(countryRow => <option key={countryRow.id} value={countryRow.id}>{countryRow.en_name}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    {/* Select Default Language */}
                                    <div className="form-group row">
                                        <label htmlFor="sctDefaultLang" className="col-sm-4 col-form-label">Default Lang</label>
                                        <div className="col-sm-8">

                                            <select id="sctDefaultLang"
                                                value={cuser.selected_lang_id}
                                                onChange={ev => setCUser({ ...cuser, selected_lang_id: Number(ev.target.value) })}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>English</option>
                                                <option value={1}>Arabic</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>

                            <div className="col-sm-4">
                            <h6 className="col-sm-12 text-center">User Image</h6>
                                <img ref={imgPreview_Ref}
                                    className="col-sm-12 img-fluid img-thumbnail"
                                    src={tempImage}
                                    alt="Company Logo"
                                />
                                <button type="button" onClick={onImageSelect} className="btn btn-success col-sm-12 mt-2">{cuser && cuser.img ? "Change" : "Select"}</button>


                                <input
                                    ref={btnImageSelect_Ref}
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={onImageSelected}
                                />
                            </div>
                        </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="col-sm-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className="text-center">View</th>
                                                <th className="text-center">Add</th>
                                                <th className="text-center">Edit</th>
                                                <th className="text-center">Delete</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td><b>Countries</b></td>
                                                <td className="text-center "><input checked={Number(cuser.countries_view)} onChange={ev => setCUser({ ...cuser, countries_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.countries_add)} onChange={ev => setCUser({ ...cuser, countries_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.countries_edit)} onChange={ev => setCUser({ ...cuser, countries_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.countries_delete)} onChange={ev => setCUser({ ...cuser, countries_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                            <tr>
                                                <td><b>Cities</b></td>
                                                <td className="text-center "><input checked={Number(cuser.cities_view)} onChange={ev => setCUser({ ...cuser, cities_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.cities_add)} onChange={ev => setCUser({ ...cuser, cities_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.cities_edit)} onChange={ev => setCUser({ ...cuser, cities_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.cities_delete)} onChange={ev => setCUser({ ...cuser, cities_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                            <tr>
                                                <td><b>Roles</b></td>
                                                <td className="text-center "><input checked={Number(cuser.roles_view)} onChange={ev => setCUser({ ...cuser, roles_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.roles_add)} onChange={ev => setCUser({ ...cuser, roles_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.roles_edit)} onChange={ev => setCUser({ ...cuser, roles_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.roles_delete)} onChange={ev => setCUser({ ...cuser, roles_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                            <tr>
                                                <td><b>Users</b></td>
                                                <td className="text-center "><input checked={Number(cuser.users_view)} onChange={ev => setCUser({ ...cuser, users_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.users_add)} onChange={ev => setCUser({ ...cuser, users_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.users_edit)} onChange={ev => setCUser({ ...cuser, users_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.users_delete)} onChange={ev => setCUser({ ...cuser, users_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                            <tr>
                                                <td><b>Companies</b></td>
                                                <td className="text-center "><input checked={Number(cuser.companies_view)} onChange={ev => setCUser({ ...cuser, companies_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.companies_add)} onChange={ev => setCUser({ ...cuser, companies_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.companies_edit)} onChange={ev => setCUser({ ...cuser, companies_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.companies_delete)} onChange={ev => setCUser({ ...cuser, companies_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                            <tr>
                                                <td><b>Branches</b></td>
                                                <td className="text-center "><input checked={Number(cuser.branches_view)} onChange={ev => setCUser({ ...cuser, branches_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.branches_add)} onChange={ev => setCUser({ ...cuser, branches_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.branches_edit)} onChange={ev => setCUser({ ...cuser, branches_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.branches_delete)} onChange={ev => setCUser({ ...cuser, branches_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                            <tr>
                                                <td><b>Screens</b></td>
                                                <td className="text-center "><input checked={Number(cuser.screens_view)} onChange={ev => setCUser({ ...cuser, screens_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.screens_add)} onChange={ev => setCUser({ ...cuser, screens_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.screens_edit)} onChange={ev => setCUser({ ...cuser, screens_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.screens_delete)} onChange={ev => setCUser({ ...cuser, screens_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                            <tr>
                                                <td><b>Presentations</b></td>
                                                <td className="text-center "><input checked={Number(cuser.presentations_view)} onChange={ev => setCUser({ ...cuser, presentations_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.presentations_add)} onChange={ev => setCUser({ ...cuser, presentations_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.presentations_edit)} onChange={ev => setCUser({ ...cuser, presentations_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.presentations_delete)} onChange={ev => setCUser({ ...cuser, presentations_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>

                                            <tr>
                                                <td><b>Slides</b></td>
                                                <td className="text-center "><input checked={Number(cuser.slides_view)} onChange={ev => setCUser({ ...cuser, slides_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                                <td className="text-center "><input checked={Number(cuser.slides_add)} onChange={ev => setCUser({ ...cuser, slides_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.slides_edit)} onChange={ev => setCUser({ ...cuser, slides_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                                <td className="text-center "><input checked={Number(cuser.slides_delete)} onChange={ev => setCUser({ ...cuser, slides_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </TabPanel>
                        </Tabs>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" onClick={onSave} className="btn btn-success">{cuser && cuser.id ? "Save" : "Add"}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Users;