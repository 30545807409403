import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useMainContext} from "../../Contexts/ContextProvider";
import 'react-tabs/style/react-tabs.css';

function Roles() {

    const {user} = useMainContext();

    const emptyRow = {
        id: null,
        is_active: 1,
        ar_name: "",
        en_name: "",
        ar_desc: "",
        en_desc: "",

        countries_view:0,
        countries_add:0,
        countries_edit:0,
        countries_delete:0,

        cities_view:0,
        cities_add:0,
        cities_edit:0,
        cities_delete:0,

        roles_view:0,
        roles_add:0,
        roles_edit:0,
        roles_delete:0,

        users_view:0,
        users_add:0,
        users_edit:0,
        users_delete:0,

        companies_view:0,
        companies_add:0,
        companies_edit:0,
        companies_delete:0,

        branches_view:0,
        branches_add:0,
        branches_edit:0,
        branches_delete:0,

        screens_view:0,
        screens_add:0,
        screens_edit:0,
        screens_delete:0,

        presentations_view:0,
        presentations_add:0,
        presentations_edit:0,
        presentations_delete:0,

        slides_view:0,
        slides_add:0,
        slides_edit:0,
        slides_delete:0,
    };
    // const [errors, setErrors] = useState();

    const [roles, setRoles] = useState();
    const [role, setRole] = useState(emptyRow);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();


    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,

        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,

        },
        {
            name: 'Description',
            selector: row => row.en_desc,
            sortable: true,
            center: true,
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,

        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
              { user.roles_edit !== 0 ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
              { user.roles_delete !== 0 ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
            </>,
        },
    ];

    useEffect(() => {

        getRoles();


    }, []);

    const getRoles = () => {

        axiosClient.get("/roles").then((response) => {

            console.log(response.data);
            setRoles(response.data);


        }).catch((error) => {

            console.log(error);
        });

    }

    const onView = (selectedRow) => {
        // setErrors(null);


        if (selectedRow) {
            setRole(selectedRow);
        }
        else {
            setRole(emptyRow);
        }

        openDetailsModal_Ref.current.click();

    }

    const onSave = () => {

        if (role.id) {
            console.log("Edit");
            // console.log(usersType);

            axiosClient.put(`/roles/${role.id}`, role).then((response) => {

                console.log("Response : ", response)

                setRoles(response.data);
                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${role.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                const response = error.response;

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        // setErrors(response.data.errors);

                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }
                }
                else {
                    console.log(response);
                }

            });
        }
        else {
            console.log("Add");
            // console.log(usersType);

            axiosClient.post(`/roles`, role).then((response) => {

                setRoles(response.data);
                console.log("Response : ", response);

                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${role.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                const response = error.response;

                console.log(response);

                if (response && response.status === 422) 
                {

                    if (response.data.errors) 
                    {
                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }
                }
                else {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/roles/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    setRoles(response.data);


                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${role.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    console.log(error);
                });

            }
        })

    }


    return (
<div className="content-wrapper">

<div className="content-header">
    <div className="container-fluid">
        <div className="row mb-2">
            <div className="col-sm-6">
                <h1 className="m-0">Roles</h1>
            </div>

            <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Roles</li>
                </ol>
            </div>

        </div>

    </div>

</div>

<div className="content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header text-right">
                        {user &&  user.roles_add !== 0 ? <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Role</button> : "" }
                    </div>

                    <div className="card-body">
                        <div className="row ">
                            <div className="form-group offset-sm-10 col-sm-2 ">
                                <div className="input-group">
                                    <input type="search" className="form-control " placeholder="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-default">
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <DataTable className="table table-bordered" columns={columns} data={roles} pagination />

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
<div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">

            <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">{role && role.id ? "Edit Role" : "Add Role"}</h5>
                <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div className="modal-body">
                {/* {
                    errors && <div className="alert alert-danger " role='alert'>
                        {
                            Object.keys(errors).map(key => (
                                <p key={key}>{errors[key][0]}</p>
                            ))
                        }
                    </div>
                } */}

                <Tabs>
                    <TabList>
                        <Tab>General</Tab>
                        <Tab>Permissions</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="col-sm-12">
                        <form className="form-horizontal">
                        <div className="form-group row">
                            <div className="offset-sm-3 col-sm-9">
                                <div className="form-check">

                                    <input className="form-check-input"
                                        checked={role.is_active}
                                        onChange={ev => setRole({ ...role, is_active: Number(ev.target.checked) })}
                                        type="checkbox"
                                        id="ckActive" />

                                    <label className="form-check-label" htmlFor="ckActive">Active</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="txtArName" className="col-sm-3 col-form-label">Name ( Arabic )</label>
                            <div className="col-sm-9">

                                <input type="text"
                                    value={role.ar_name}
                                    onChange={ev => setRole({ ...role, ar_name: ev.target.value })}
                                    className="form-control"
                                    id="txtArName"
                                    placeholder="Arabic Name" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="txtEnName" className="col-sm-3 col-form-label">Name ( English )</label>
                            <div className="col-sm-9">
                                <input type="text"
                                    value={role.en_name}
                                    onChange={ev => setRole({ ...role, en_name: ev.target.value })}
                                    className="form-control"
                                    id="txtEnName"
                                    placeholder="English Name" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="txtArDesc" className="col-sm-3 col-form-label">Desc ( Arabic )</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" id="txtArDesc" rows="3" value={role.ar_desc} onChange={ev => setRole({ ...role, ar_desc: ev.target.value })}></textarea>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="txtEnDesc" className="col-sm-3 col-form-label">Desc ( English )</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" id="txtEnDesc" rows="3" value={role.en_desc} onChange={ev => setRole({ ...role, en_desc: ev.target.value })}></textarea>
                            </div>
                        </div>

                        </form>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="col-sm-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="text-center">View</th>
                                        <th className="text-center">Add</th>
                                        <th className="text-center">Edit</th>
                                        <th className="text-center">Delete</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td><b>Countries</b></td>
                                        <td className="text-center "><input checked={Number(role.countries_view)} onChange={ev => setRole({ ...role, countries_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.countries_add)} onChange={ev => setRole({ ...role, countries_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.countries_edit)} onChange={ev => setRole({ ...role, countries_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.countries_delete)} onChange={ev => setRole({ ...role, countries_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                    <tr>
                                        <td><b>Cities</b></td>
                                        <td className="text-center "><input checked={Number(role.cities_view)} onChange={ev => setRole({ ...role, cities_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.cities_add)} onChange={ev => setRole({ ...role, cities_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.cities_edit)} onChange={ev => setRole({ ...role, cities_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.cities_delete)} onChange={ev => setRole({ ...role, cities_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                    <tr>
                                        <td><b>Roles</b></td>
                                        <td className="text-center "><input checked={Number(role.roles_view)} onChange={ev => setRole({ ...role, roles_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.roles_add)} onChange={ev => setRole({ ...role, roles_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.roles_edit)} onChange={ev => setRole({ ...role, roles_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.roles_delete)} onChange={ev => setRole({ ...role, roles_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                    <tr>
                                        <td><b>Users</b></td>
                                        <td className="text-center "><input checked={Number(role.users_view)} onChange={ev => setRole({ ...role, users_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.users_add)} onChange={ev => setRole({ ...role, users_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.users_edit)} onChange={ev => setRole({ ...role, users_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.users_delete)} onChange={ev => setRole({ ...role, users_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                    <tr>
                                        <td><b>Companies</b></td>
                                        <td className="text-center "><input checked={Number(role.companies_view)} onChange={ev => setRole({ ...role, companies_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.companies_add)} onChange={ev => setRole({ ...role, companies_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.companies_edit)} onChange={ev => setRole({ ...role, companies_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.companies_delete)} onChange={ev => setRole({ ...role, companies_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                    <tr>
                                        <td><b>Branches</b></td>
                                        <td className="text-center "><input checked={Number(role.branches_view)} onChange={ev => setRole({ ...role, branches_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.branches_add)} onChange={ev => setRole({ ...role, branches_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.branches_edit)} onChange={ev => setRole({ ...role, branches_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.branches_delete)} onChange={ev => setRole({ ...role, branches_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                    <tr>
                                        <td><b>Screens</b></td>
                                        <td className="text-center "><input checked={Number(role.screens_view)} onChange={ev => setRole({ ...role, screens_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.screens_add)} onChange={ev => setRole({ ...role, screens_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.screens_edit)} onChange={ev => setRole({ ...role, screens_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.screens_delete)} onChange={ev => setRole({ ...role, screens_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                    <tr>
                                        <td><b>Presentations</b></td>
                                        <td className="text-center "><input checked={Number(role.presentations_view)} onChange={ev => setRole({ ...role, presentations_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.presentations_add)} onChange={ev => setRole({ ...role, presentations_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.presentations_edit)} onChange={ev => setRole({ ...role, presentations_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.presentations_delete)} onChange={ev => setRole({ ...role, presentations_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>

                                    <tr>
                                        <td><b>Slides</b></td>
                                        <td className="text-center "><input checked={Number(role.slides_view)} onChange={ev => setRole({ ...role, slides_view: Number(ev.target.checked) })} className="align-middle" type="checkbox"/></td>
                                        <td className="text-center "><input checked={Number(role.slides_add)} onChange={ev => setRole({ ...role, slides_add: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.slides_edit)} onChange={ev => setRole({ ...role, slides_edit: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                        <td className="text-center "><input checked={Number(role.slides_delete)} onChange={ev => setRole({ ...role, slides_delete: Number(ev.target.checked) })}className="align-middle" type="checkbox"  /></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </TabPanel>
                </Tabs>

            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" onClick={onSave} className="btn btn-success">{role && role.id ? "Save" : "Add"}</button>
            </div>
        </div>
    </div>
</div>
</div>
    );
}

export default Roles;