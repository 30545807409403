import { useEffect, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import {useMainContext} from "../../Contexts/ContextProvider"

import { Link } from "react-router-dom";

function Dashboard() {
  const {user} = useMainContext();

  const [dashboard,setDashboard] =  useState();

  useEffect(()=>  {
    getDashboard();
},[]);

const getDashboard = () => {

  axiosClient.get("misc/dashboard").then((response) => {
    user.role === null && console.log("Dashboard",response.data);
      setDashboard(response.data);
      
  }).catch((error)=> {

      console.log(error);
     
  });

}
  return (      
  <div className="content-wrapper">
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0">Dashboard</h1>
        </div>

        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>

    </div>

  </div>

  <div className="content">
    <div className="container-fluid">
               <div className="row">

              {user != null && user.countries_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.countries}</h3>

                      <p>Countries</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-flag"></i>
                    </div>
                    <Link to={"/dashboard/countries"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : "" }

                {user != null && user.cities_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.cities}</h3>

                      <p>Cities</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-city"></i>
                    </div>
                    <Link to={"/dashboard/cities"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

                {user != null && user.roles_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.roles}</h3>

                      <p>Roles</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-users-cog"></i>
                    </div>
                    <Link to={"/dashboard/roles"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

                {user != null && user.users_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.users}</h3>

                      <p>Users</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-users"></i>
                    </div>
                    <Link to={"/dashboard/users"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

                {user != null && user.companies_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.companies}</h3>

                      <p>Companies</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-building"></i>
                    </div>
                    <Link to={"/dashboard/companies"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

                {user != null && user.branches_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.branches}</h3>

                      <p>Branches</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-code-branch"></i>
                    </div>
                    <Link to={"/dashboard/branches"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

                {user != null && user.screens_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.screens}</h3>

                      <p>Screens</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-tv"></i>
                    </div>
                    <Link to={"/dashboard/screens"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

                {user != null && user.presentations_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.presentations}</h3>

                      <p>Presentations</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-film"></i>
                    </div>
                    <Link to={"/dashboard/presentations"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

                {user != null && user.presentations_view ? <div className="col-lg-3 col-6">
                  <div className="small-box bg-light">
                    <div className="inner">
                      <h3>{dashboard && dashboard.slides}</h3>

                      <p>Slides</p>
                    </div>
                    <div className="icon">
                      <i className="fas fa-photo-video"></i>
                    </div>
                    <Link to={"/dashboard/slides"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                  </div>
                </div> : ""}

              </div>
    </div>
  </div> 

</div>)
}

export default Dashboard