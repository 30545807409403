import React, { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom";
import axiosClient from "../../Helpers/Axios-Client";
import {useMainContext} from "../../Contexts/ContextProvider"
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';

function Profile() {

  const emptyRow = {
    id: null,
    role: null,
    name: "",
    img: "",
    mobile: "",
    email: "",
    country: null,
    selected_lang_id: 0,
    // current_password:"",
    new_password:"",
    confirmation_password:""
  };

  const {setUserForCredentials} = useMainContext();

  const [cuser, setCUser] = useState(emptyRow);

  const [tempImage, setTempImage] = useState(null);
  const btnImageSelect_Ref = useRef();
  const imgPreview_Ref = useRef();

  useEffect(() => {

    getProfile();

  }, []);


  const getProfile = () => {

    axiosClient.get("/profile").then((response) => {
      console.log(response.data);
      setCUser(response.data);
      // setTempImage( cuser.img ? `${process.env.REACT_APP_STORAGE_URL}/${cuser.img}` : "/img/No_Image_Available.jpg");

    }).catch((error) => {

      console.log(error);
    });

  }

  const onImageSelect = (ev) => {

    btnImageSelect_Ref.current.click();
  }

  const onImageSelected = (ev) => {

    if (ev.target.files.length > 0) 
    {
      setCUser({ ...cuser, img: URL.createObjectURL(ev.target.files[0]) });
      setTempImage(ev.target.files[0]);
    }

  }

  const onUpdateInformation = () => {

        const formData = new FormData();
        formData.append("id", cuser.id);
        formData.append("name", cuser.name);
        formData.append("mobile", cuser.mobile);
        formData.append("email", cuser.email);
        formData.append("selected_lang_id", cuser.selected_lang_id);

        if (tempImage != null) 
        {
            formData.append("img", tempImage);
        }

        axiosClient.post("/profile", formData, { headers: { "Content-Type": "multipart/form-data" }, }).then((response) => {

            console.log("Response : ", response);

            if(response.data != null)
            {
              setUserForCredentials(response.data.user);

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${cuser.en_name} has been updated`,
                showConfirmButton: false,
                timer: 1000
            });
            }

        }).catch((error) => {

            const response = error.response;

            if (response && response.status === 422) {

                if (response.data.errors) 
                {
                  Object.keys(response.data.errors).map(key => (
                    toast.error(response.data.errors[key][0])
                  ));
                    
                }
            }
            else {
                console.log(response);
            }

        });
  } 

  const onUpdatePassword = () => {
    axiosClient.post(`/change-password`, cuser).then((response) => {

      console.log("Response : ", response)

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: `Your password has been updated`,
        showConfirmButton: false,
        timer: 1000
      });


    }).catch((error) => {

      const response = error.response;

      if (response && response.status === 422) {

        if (response.data.errors) 
        {
          Object.keys(response.data.errors).map(key => (
            toast.error(response.data.errors[key][0])
          ));
        }
      }
      else {
        console.log(response);
      }

    });

  }

  return (
    <div className="content-wrapper">

      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Profile</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                <li className="breadcrumb-item active">Profile</li>
              </ol>
            </div>
          </div>

        </div>

      </div>

      <div className="content">

        <div className="container-fluid">
            <div className="card col-sm-8">
              <div className='card-header'>
                <h3>General Information</h3>
              </div>
              <div className="card-body">
                <div className='row'>

                  <div className="col-sm-9">
                    <form className="form-horizontal">
                      {/* Is Active */}
                      <div className="form-group row">
                        <label htmlFor="txtName" className="col-md-2 col-form-label">Status</label>
                        <div className="col-sm-10">
                          {(cuser.is_active) ? <span className="badge badge-success mt-2" >Active</span> : <span className="badge badge-danger" >Inactive</span>}
                        </div>
                      </div>

                      {/* User Role */}
                      <div className="form-group row">
                        <b className="col-sm-2 col-form-label">Role</b>
                        <div className="col-sm-10">
                          <div className='mt-2'><b>{cuser.role != null ? cuser.role.en_name : "Super Admin"}</b></div>
                        </div>
                      </div>

                      {/* Name */}
                      <div className="form-group row">
                        <label htmlFor="txtName" className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-10">

                          <input type="text"
                            value={cuser.name}
                            onChange={ev => setCUser({ ...cuser, name: ev.target.value })}
                            className="form-control"
                            id="txtName"
                            placeholder="Full Name" />
                        </div>
                      </div>

                      {/* EMail */}
                      <div className="form-group row">
                        <label htmlFor="txtEMail" className="col-sm-2 col-form-label">EMail</label>
                        <div className="col-sm-10">
                          <input type="email"
                            value={cuser.email}
                            onChange={ev => setCUser({ ...cuser, email: ev.target.value })}
                            className="form-control"
                            id="txtEMail"
                            placeholder="EMail" />
                        </div>
                      </div>

                      {/* Mobile */}
                      <div className="form-group row">
                        <label htmlFor="txtMobile" className="col-sm-2 col-form-label">Mobile</label>
                        <div className="col-sm-10">
                          <input type="phone"
                            value={cuser.mobile}
                            onChange={ev => setCUser({ ...cuser, mobile: ev.target.value })}
                            className="form-control"
                            id="txtMobile"
                            placeholder="Mobile" />
                        </div>
                      </div>


                      {/*  Country */}
                      <div className="form-group row">
                        <b className="col-sm-2 col-form-label">Country</b>
                        <div className="col-sm-10">
                          {cuser.role != null ? <div className='mt-2'><i className={`fi mr-2 fi-${cuser.country.domain_ext}`} /><span>{cuser.country.en_name}</span></div> : <div className='mt-2'><b>International</b></div>}
                        </div>
                      </div>

                      {/* Select Default Language */}
                      <div className="form-group row">
                        <label htmlFor="sctDefaultLang" className="col-sm-2 col-form-label">Default Lang</label>
                        <div className="col-sm-10">

                          <select id="sctDefaultLang"
                            value={cuser.selected_lang_id}
                            onChange={ev => setCUser({ ...cuser, selected_lang_id: Number(ev.target.value) })}
                            className="form-control form-select form-select-lg"
                            aria-label="Default select example">
                            <option value={0}>English</option>
                            <option value={1}>Arabic</option>
                          </select>
                        </div>
                      </div>

                    </form>

                    <button className="btn btn-success col-sm-12 mt-4" type="button" onClick={onUpdateInformation} >Update</button>

                  </div>

                  <div className="col-sm-3">
                    <h5 className='text-center'>User Image</h5>
                    <img ref={imgPreview_Ref}
                      className="col-sm-12 img-fluid img-thumbnail"
                      src={cuser.img ? cuser.img : "/img/No_Image_Available.jpg"}
                      alt="user logo"
                    />
                    <input
                      ref={btnImageSelect_Ref}
                      style={{ display: "none" }}
                      type="file"
                      onChange={onImageSelected}
                    />
                    <button type="button" onClick={onImageSelect} className="btn btn-success col-sm-12 mt-2">{cuser && cuser.img ? "Update" : "Select"}</button>

                  </div>
                </div>

              </div>

            </div>

            <div className="card col-sm-5">
              <div className='card-header'>
                <h3>Password & Security</h3>
              </div>
              <div className="card-body">
                <div className="col-sm-12">
                  <form className="form-horizontal">

                    {/* current Password */}
                    {/* <div className="form-group row">
                      <label htmlFor="txtCurrentPassword" className="col-sm-4 col-form-label">Current Password</label>
                      <div className="col-sm-8">
                        <input type="password"
                          // value={cuser.current_password}
                          onChange={ev => setCUser({ ...cuser, current_password: ev.target.value })}
                          className="form-control"
                          id="txtCurrentPassword"
                          placeholder="Current Password"
                        />
                      </div>
                    </div> */}

                    {/* Password */}
                    <div className="form-group row">
                      <label htmlFor="txtNewPassword" className="col-sm-4 col-form-label">New Password</label>
                      <div className="col-sm-8">
                        <input type="password"
                          value={cuser.new_password}
                          onChange={ev => setCUser({ ...cuser, new_password: ev.target.value })}
                          className="form-control"
                          id="txtNewPassword"
                          placeholder="New Password"
                        />
                      </div>
                    </div>

                    {/* Password Confirmation */}
                    <div className="form-group row">
                      <label htmlFor="txtPasswordConfirmation" className="col-sm-4 col-form-label">Password Confirm</label>
                      <div className="col-sm-8">
                        <input type="password"
                          // value={cuser.confirmation_password}
                          onChange={ev => setCUser({ ...cuser, confirmation_password: ev.target.value })}
                          className="form-control"
                          id="txtPasswordConfirmation"
                          placeholder="Password Confirmation"
                        />
                      </div>
                    </div>
                  </form>

                  <button className="btn btn-success col-sm-12 mt-4" type="button" onClick={onUpdatePassword} >Update</button>

                </div>
              </div>

            </div>
        </div>
      </div>

    </div>

  )
}

export default Profile