import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link,useLocation } from "react-router-dom";
import {useMainContext} from "../../Contexts/ContextProvider";

function Branches() 
{
    const {user} = useMainContext();

    const location = useLocation();
    const perantCompany = location.state;

    const initRowValues = {
        id:null,
        is_active:true,
        ar_name:"",
        en_name:"",
        city:null,
        company: perantCompany,
    };

    // const [errors,setErrors] = useState(null);
    const [companies,setCompanies] =  useState();
    const [cities,setCities] =  useState();
    const [branches,setBranches] =  useState();
    const [branch,setBranch] =  useState(initRowValues);
  
    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center:true,
            width:"80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center:true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> :  <span className="badge badge-danger" >Inactive</span>,
        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            cell: row => <span>{row.city.en_name}</span>
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
            cell: row => <div>
                            <img src={row.company.logo ? `${process.env.REACT_APP_STORAGE_URL}/${row.company.logo}` : "/img/No_Image_Available.jpg"} className="img-circle m-2 elevation-2" width="40" alt="Company Logo" />
                            <span>{row.company.en_name}</span>
                         </div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center:true,
        },
        {
            name: '',
            selector: row => row.actions,
            center:true,
            width:"300px",
            cell: row => <>
                            {user.screens_view !== 0 || user.screens_add !== 0 || user.screens_edit !== 0 || user.screens_delete !== 0 ? <Link state={row}  to={`${row.en_name.replace(/\s+/g, '-').toLowerCase()}/screens`} className="btn btn-warning btn-sm text-white mr-2"><i className="fa fa-tv"></i> Screens</Link> : "" }
                            {user.branches_edit !== 0  ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                            {user.branches_delete !== 0  ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
                        </>,
        },
    ];
    
    useEffect(()=>  {

        getCompanies();
        getCities();
        getBranches();

    },[]);

    const getCompanies = () => {

        axiosClient.get("/companies").then((response) => {

           // console.log(response.data);
            setCompanies(response.data);
        
           
        }).catch((error)=> {

            console.log(error);
           
        });

    }

    const getBranches = () => {

        if (perantCompany && perantCompany.id) {
            
            axiosClient.get(`/branches/${perantCompany.id}`).then((response) => {

                console.log(response.data);
                setBranches(response.data);
               
            }).catch((error)=> {
    
                console.log(error);
            });
        }
        else
        {
            axiosClient.get("/branches").then((response) => {

                console.log(response.data);
                setBranches(response.data);
               
            }).catch((error)=> {
    
                console.log(error);
            });
        }
   
    }

    const getCities = () => {

        axiosClient.get("/cities").then((response) => {

            console.log(response.data);
            setCities(response.data);
           
        }).catch((error)=> {

            console.log(error);
        });
    }

    const onView = (selectedRow) => 
    {
        // setErrors(null);

        if(selectedRow)
        {
            setBranch(selectedRow);
        }
        else
        {
            setBranch(initRowValues);
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = () => {
        // setErrors(null);

        if(branch.id)
        {
            console.log("Edit");
            console.log(branch);

            axiosClient.put(`/branches/${branch.id}`,branch).then((response) => {

                console.log("Response : " , response)

                getBranches();
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${branch.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
    
            }).catch((error)=> {
    
                const response = error.response;

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    // setErrors(response.data.errors);
                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }
       
                }
                else
                {
                    console.log(response);
                }

            });
        }
        else
        {
            console.log("Add");

            axiosClient.post(`/branches`,branch).then((response) => {

                getBranches();
                console.log("Response : " , response);

                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${branch.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
            }).catch((error)=> {
    
                const response = error.response;

                console.log(response);

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    // setErrors(response.data.errors);

                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }
  
                }
                else
                {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
          }).then((result) => {

            if (result.isConfirmed) 
            {

                axiosClient.delete(`/branches/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    getBranches();

                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${branch.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                      });

                }).catch((error) => {

                    console.log(error);
                });

            }
          })

    }

  return (
    <div className="content-wrapper">
    
    <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">{perantCompany && perantCompany.en_name} Branches</h1>
            </div>
       
            <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to={"/dashboard/companies"} className="text-success">Companies</Link></li>

                <li className="breadcrumb-item active">{perantCompany && perantCompany.en_name} Branches</li>
            </ol>
            
            </div>
           
        </div>
        
        </div>
       
    </div>

    <div className="content">
        <div className="container-fluid">
        <div className="row">
            <div className="col-12">
            <div className="card">
                <div className="card-header text-right">
                      {user &&  user.branches_add !== 0  ? <button onClick={ev => onView(null)}  type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Branch</button> : "" }
                </div>
              
                <div className="card-body">
                    <div className="row ">
                        <div className="form-group offset-sm-10 col-sm-2 ">
                            <div className="input-group">
                            <input  type="search" className="form-control " placeholder="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-default">
                                <i className="fa fa-search" />
                                </button>
                            </div>
                            </div>

                        </div>

                    </div>

                    <DataTable className="table table-bordered" columns={columns} data={branches} pagination />

                </div>
            </div>
            
            </div>
        
        </div>
        </div>
    </div>


        <button style={{display:"none"}}  ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
        <div  className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

            <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">{branch && branch.id  ? "Edit Branch" : "Add Branch"}</h5>
                <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div className="modal-body">
            {/* {
            errors && <div className="alert alert-danger " role='alert'>
                {
                Object.keys(errors).map(key => (
                    <p key={key}>{errors[key][0]}</p>
                ))
                }
            </div>
            } */}

            <form className="form-horizontal">
                <div className="form-group row">
                        <div className="offset-sm-3 col-sm-9">
                            <div className="form-check">

                            <input className="form-check-input " 
                                    checked={branch.is_active}
                                    onChange={ev => setBranch({...branch,is_active: Number(ev.target.checked)})}
                                    type="checkbox" 
                                    id="ckActive" />

                            <label className="form-check-label" htmlFor="ckActive">Active</label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="txtArName" className="col-sm-3 col-form-label">Name ( Arabic )</label>
                        <div className="col-sm-9">

                            <input type="text" 
                                value={branch.ar_name}
                                onChange={ev => setBranch({...branch,ar_name: ev.target.value})}
                                className="form-control" 
                                id="txtArName" 
                                placeholder="Arabic Name" />
                        </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="txtEnName" className="col-sm-3 col-form-label">Name ( English )</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                value={branch.en_name}
                                onChange={ev => setBranch({...branch,en_name: ev.target.value})}
                                className="form-control" 
                                id="txtEnName" 
                                placeholder="English Name" />
                        </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="sctCittes" className="col-sm-3 col-form-label">City</label>
                        <div className="col-sm-6">
                            
                            <select id="sctCittes"
                                    value={branch.city ? branch.city.id : 0}
                                    onChange={ev => setBranch({...branch,city: cities.find(o => o.id === Number(ev.target.value))})}
                                    className="form-control form-select form-select-lg" 
                                    aria-label="Default select example">
                                    <option value={0}>Select City</option>
                                    {cities && cities.map(cityRow => <option key={cityRow.id} value={cityRow.id}>{cityRow.en_name}</option>)}
                            </select>
                        </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="sctCompanies" className="col-sm-3 col-form-label">Company</label>
                        <div className="col-sm-6">
                            
                            <select id="sctCompanies"
                                    // disabled={perantCompany && perantCompany.id ? true : false}
                                    value={branch.company ? branch.company.id : 0} 
                                    onChange={ev => setBranch({...branch,company: companies.find(o => o.id === Number(ev.target.value))})}
                                    className="form-control form-select form-select-lg" 
                                    aria-label="Default select example">
                                    <option value={0}>Select Company</option>
                                    {companies && companies.map(companyRow => <option key={companyRow.id} value={companyRow.id}>{companyRow.en_name}</option>)}
                            </select>
                        </div>
                </div>


                </form>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" onClick={onSave} className="btn btn-success">{ branch && branch.id  ? "Save" : "Add"}</button>
            </div>
            </div>
        </div>
        </div>
    </div>

  );
}

export default Branches;