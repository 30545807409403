import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useMainContext} from "../../Contexts/ContextProvider";
import 'react-tabs/style/react-tabs.css';

function Slides() {

    const {user} = useMainContext();

    const location = useLocation();
    const perantPresentation = location.state;

    const initRowValues = {
        id: null,
        is_active: 1,
        ar_name: "",
        en_name: "",
        media_src: "",
        duration_type: 0,
        duration_value: 2,
        transition_effect_type: 0,
        presentation: perantPresentation,
    };

    const [presentations, setPresentations] = useState();
    const [slides, setSlides] = useState();
    const [slide, setSlide] = useState(initRowValues);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const [tempImage, setTempImage] = useState(null);
    const btnImageSelect_Ref = useRef();
    const imgPreview_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "60px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            width: "90px",
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,
        },
        {
            name: 'Media',
            selector: row => row.media_src,
            sortable: true,
            center: true,
            cell: row => <img src={row.media_src ? row.media_src : "/img/No_Image_Available.jpg"} className="img-thumbnail m-2 elevation-2" width="100" alt="Presentation" />
        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
        },

        {
            name: 'Duration',
            selector: row => row.duration_type,
            sortable: true,
            center: true,
            width: "100px",
            cell: row => {

                var duration="";
                switch (Number(row.duration_type)) {
                    case 0:
                        duration = <span>{row.duration_value} Second</span>
                        break;

                    case 1:
                        duration = <span>{row.duration_value} Minute</span>
                        break;

                    case 2:
                        duration = <span>{row.duration_value} Hour</span>
                        break;

                    case 3:
                        duration = <span>{row.duration_value} Day</span>
                        break;

                    default:
                        duration = <span>{row.duration_value} Unknow</span>
                }

                return duration;

            }
        },
        {
            name: 'Effect',
            selector: row => row.transition_effect_type,
            sortable: true,
            center: true,
            width: "90px",
            cell: row => {

                if (row.transition_effect_type === 0) {
                    return <span>Direct</span>
                }
                else if (row.transition_effect_type === 1) {
                    return <span>Fade</span>
                }
                else {
                    return <span>Unknow</span>
                }

            }
        },
        {
            name: 'Presentation',
            selector: row => row.presentation.en_name,
            sortable: true,
            center: true,
            width: "120px",
        },
        {
            name: 'Company',
            selector: row => row.presentation.company.en_name,
            sortable: true,
            cell: row => <div>
                            <img src={row.presentation.company.logo ? `${process.env.REACT_APP_STORAGE_URL}/${row.presentation.company.logo}` : "/img/No_Image_Available.jpg"} className="img-circle m-2 elevation-2" width="40" alt="Company Logo" />
                            <span>{row.presentation.company.en_name}</span>
                        </div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,
        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                { user.Slides_edit !== 0 ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                { user.Slides_delete !== 0 ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
            </>,
        },
    ];

    useEffect(() => {
        getSlides();
        getPresentations();

    }, []);

    const getPresentations = () => {

        axiosClient.get("/presentations").then((response) => {

            console.log(response.data);
            setPresentations(response.data);
  
        }).catch((error) => {

            console.log(error);

        });

    }

    const getSlides = () => {

        if (perantPresentation && perantPresentation.id) {
            axiosClient.get(`/slides/${perantPresentation.id}`).then((response) => {

                console.log(response.data);
                setSlides(response.data);

            }).catch((error) => {

                console.log(error);
            });
        }
        else {
            axiosClient.get("/slides").then((response) => {

                console.log(response.data);
                setSlides(response.data);

            }).catch((error) => {

                console.log(error);
            });
        }
    }

    const onView = (selectedRow) => {
        setTempImage(null);

        if (selectedRow) {
            setSlide(selectedRow);
        }
        else {
            setSlide(initRowValues);

        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = () => {
        // setErrors(null);

        if (slide.id) {
            console.log("Edit",slide);

            const formData = new FormData();
            formData.append("_method","PUT");
            formData.append("id",slide.id);
            formData.append("is_active",slide.is_active);
            formData.append("ar_name",slide.ar_name);
            formData.append("en_name",slide.en_name);
            formData.append("duration_type",slide.duration_type);
            formData.append("duration_value",slide.duration_value);
            formData.append("transition_effect_type",slide.transition_effect_type);
            formData.append("presentation[id]",slide.presentation != null ? slide.presentation.id : null);

            if(tempImage != null)
            {
                formData.append("media_src",tempImage);
            }

            axiosClient.post(`/slides/${slide.id}`,formData,{headers: { "Content-Type": "multipart/form-data" },}).then((response) => {

                console.log("Response : ", response)

                getSlides();
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${slide.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                const response = error.response;

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }
                }
                else {
                    console.log(response);
                }

            });
        }
        else {
            console.log("Add",slide);

            const formData = new FormData();
            formData.append("id",slide.id);
            formData.append("is_active",slide.is_active);
            formData.append("ar_name",slide.ar_name);
            formData.append("en_name",slide.en_name);
            formData.append("duration_type",slide.duration_type);
            formData.append("duration_value",slide.duration_value);
            formData.append("transition_effect_type",slide.transition_effect_type);
            formData.append("presentation[id]",slide.presentation != null ? slide.presentation.id : null);
            formData.append("media_src",tempImage);

            axiosClient.post(`/slides`,formData,{ headers: { "Content-Type": "multipart/form-data" }}).then((response) => {

                getSlides();
                console.log("Response : ", response);

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${slide.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                const response = error.response;

                console.log(response);

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (
                            toast.error(response.data.errors[key][0])
                          ));
                    }
                }
                else {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/slides/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    getSlides();


                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${slide.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    console.log(error);
                });

            }
        })

    }

    const onImageSelect = (ev) => {

        btnImageSelect_Ref.current.click();
    }
    
    const onImageSelected = (ev) => {

        if (ev.target.files.length > 0) {
            setSlide({ ...slide, media_src: URL.createObjectURL(ev.target.files[0])});
            setTempImage(ev.target.files[0]);
        }
    }

    return (
        <div className="content-wrapper">

        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{perantPresentation && perantPresentation.en_name} Slides</h1>
                    </div>

                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                            <li className="breadcrumb-item"><Link to={"/dashboard/presentations"} className="text-success">Presentations</Link></li>
                            <li className="breadcrumb-item active">{perantPresentation && perantPresentation.en_name} Slides</li>
                        </ol>
                    </div>

                </div>

            </div>

        </div>

        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header text-right">
                            {user &&  user.Slides_add !== 0 ?  <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Slide</button> : "" }
                            </div>

                            <div className="card-body">
                                <div className="row ">
                                    <div className="form-group offset-sm-10 col-sm-2 ">
                                        <div className="input-group">
                                            <input type="search" className="form-control " placeholder="Search" />
                                            <div className="input-group-append">
                                                <button className="btn btn-default">
                                                    <i className="fa fa-search" />
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <DataTable className="table table-bordered" columns={columns} data={slides} pagination />

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>


        <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
        <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="exampleModalLabel">{slide && slide.id ? "Edit Slide" : "Add Slide"}</h5>
                        <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <Tabs>
                            <TabList>
                                <Tab>General</Tab>
                                <Tab>Media Source</Tab>
                            </TabList>

                            <TabPanel>
                            <form className="form-horizontal">
                                    <div className="form-group row">
                                        <div className="offset-sm-3 col-sm-9">
                                            <div className="form-check">

                                                <input className="form-check-input "
                                                    checked={slide.is_active}
                                                    onChange={ev => setSlide({ ...slide, is_active: Number(ev.target.checked) })}
                                                    type="checkbox"
                                                    id="ckActive" />

                                                <label className="form-check-label" htmlFor="ckActive">Active</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="txtArName" className="col-sm-3 col-form-label">Name ( Arabic )</label>
                                        <div className="col-sm-9">

                                            <input type="text"
                                                value={slide.ar_name}
                                                onChange={ev => setSlide({ ...slide, ar_name: ev.target.value })}
                                                className="form-control"
                                                id="txtArName"
                                                placeholder="Arabic Name" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="txtEnName" className="col-sm-3 col-form-label">Name ( English )</label>
                                        <div className="col-sm-9">
                                            <input type="text"
                                                value={slide.en_name}
                                                onChange={ev => setSlide({ ...slide, en_name: ev.target.value })}
                                                className="form-control"
                                                id="txtEnName"
                                                placeholder="English Name" />
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <label htmlFor="txtMediaDurationValue" className="col-sm-3 col-form-label">Duration</label>

                                        <div className="col-sm-6">
                                            <input type="text"
                                                value={slide.duration_value}
                                                onChange={ev => setSlide({ ...slide, duration_value: ev.target.value })}
                                                className="form-control"
                                                id="txtMediaDurationValue"
                                                placeholder="Duration Value" />
                                        </div>

                                        <div className="col-sm-3">
                                            <select id="sctMediaDurationType"
                                                value={slide.duration_type}
                                                onChange={ev => setSlide({ ...slide, duration_type: ev.target.value })}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>Second</option>
                                                <option value={1}>Minute</option>
                                                <option value={2}>Hour</option>
                                                <option value={3}>Day</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="sctTransitionEffect" className="col-sm-3 col-form-label">Transition Effect</label>
                                        <div className="col-sm-6">

                                            <select id="sctTransitionEffect"
                                                value={slide.transition_effect_type}
                                                onChange={ev => setSlide({ ...slide, transition_effect_type: ev.target.value })}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>Direct</option>
                                                <option value={1}>Fade</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <label htmlFor="sctPresentation" className="col-sm-3 col-form-label">Presentation</label>
                                        <div className="col-sm-6">

                                            <select id="sctPresentation"
                                                // disabled={perantPresentation && perantPresentation.id ? true : false}
                                                value={slide.presentation && slide.presentation.id ? slide.presentation.id : 0}
                                                onChange={ev => setSlide({ ...slide, presentation: presentations.find(o => o.id === Number(ev.target.value)) })}
                                                className="form-control form-select form-select-lg"
                                                aria-label="Default select example">
                                                <option value={0}>Select Presentation</option>
                                                {presentations && presentations.map(presentationRow => <option key={presentationRow.id} value={presentationRow.id}>{presentationRow.en_name}</option>)}
                                            </select>
                                        </div>
                                    </div>


                                </form>
                            </TabPanel>

                            <TabPanel>
                            <div className="col-sm-12">
                                <img ref={imgPreview_Ref}
                                    className="col-sm-12 img-fluid img-thumbnail"
                                    src={slide.media_src ? slide.media_src : "/img/No_Image_Available.jpg"}
                                    alt="media"
                                />
                                <button type="button" onClick={onImageSelect} className="btn btn-success col-sm-12 mt-2">{slide && slide.media_src ? "Change" : "Select"}</button>


                                <input
                                    ref={btnImageSelect_Ref}
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={onImageSelected}
                                />
                            </div>
                            </TabPanel>
                        </Tabs>


                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" onClick={onSave} className="btn btn-success">{slide && slide.id ? "Save" : "Add"}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Slides;