import { Link } from "react-router-dom";
import { useMainContext } from "../../../Contexts/ContextProvider"
import axiosClient from "../../../Helpers/Axios-Client"

function Header() {
  const { user, setCredentials } = useMainContext();




  const btnLogout_OnClick = (e) => {
    e.preventDefault();


    axiosClient.post("/logout").then(() => {

      setCredentials(null, null);
    }).catch((err) => {

      console.log(err);

    });

  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="/#" role="button"><i className="fas fa-bars" /></a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">

        {/* Notifications Dropdown Menu */}
        {/* <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="/#">
            <i className="fas fa-bell fa-lg" />
            <span className="badge badge-warning navbar-badge">2</span>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-header">2 Notifications</span>
            <div className="dropdown-divider" />
            <a href="/#" className="dropdown-item">
              <i className="fas fa-tv mr-2" /> Screen 1 is connected
              <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider" />
            <a href="/#" className="dropdown-item">
              <i className="fas fa-tv mr-2" />   Screen 1 is disconnected
              <span className="float-right text-muted text-sm">1 hours</span>
            </a>
            <div className="dropdown-divider" />
            <a href="/#" className="dropdown-item dropdown-footer">See All Notifications</a>
          </div>
        </li> */}

        {/* User panel */}
        <li className="nav-item dropdown user-menu">
          <a href="/#" className="nav-link dropdown-toggle" data-toggle="dropdown">
            <img src={user.img ? `${process.env.REACT_APP_STORAGE_URL}/${user.img}` : "/img/No_Image_Available.jpg"} className="user-image img-circle elevation-2" alt="User " />
            <span className="d-none d-md-inline">{ }</span>
          </a>

          <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            {/* User image */}
            <li className="user-header">
              <img src={user.img ? `${process.env.REACT_APP_STORAGE_URL}/${user.img}` : "/img/No_Image_Available.jpg"} className="img-circle elevation-2" alt="User " />

              <p>
                {user.name}
                <small>{user.role != null ? user.role.en_name : "Super Admin"}</small>
                <small>Member since {user.created_at}</small>
              </p>
            </li>
            {/* Menu Footer*/}
            <li className="user-footer">
              <Link to={"/profile"} className="btn btn-default btn-flat">Profile</Link>
              <button onClick={btnLogout_OnClick} className="btn btn-default btn-flat float-right">Sign out</button>

            </li>
          </ul>
        </li>
        {/* Language Dropdown Menu */}
        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="/#"><i className="fi fi-us mr-2" /> English</a>
          <div className="dropdown-menu dropdown-menu-right p-0">
            <a href="/#" className="dropdown-item active"><i className="fi fi-us mr-2" /> English</a>
            <a href="/#" className="dropdown-item"><i className="fi fi-sa mr-2" /> Arabic</a>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Header;
