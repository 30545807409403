import { useEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import {toast } from 'react-toastify';
import { Link,useLocation } from "react-router-dom";
import {useMainContext} from "../../Contexts/ContextProvider"

function Cities() 
{
    const {user} = useMainContext();
    const location = useLocation();
    const perantCountry = location.state;
    
    const initRowValue = {
        id:null,
        is_active:true,
        ar_name:"",
        en_name:"",
        country: perantCountry,
    };

    // const [errors,setErrors] = useState(null);

    const [cities,setCities] =  useState();
    const [countries,setCountries] =  useState();
    const [city,setCity] =  useState(initRowValue);
  
    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center:true,
            width:"80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center:true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> :  <span className="badge badge-danger" >Inactive</span>,
        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.country_id,
            sortable: true,
            cell: row => <div><span className={"fi mr-2 fi-" + row.country.domain_ext}></span><span>{row.country.en_name}</span></div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center:true,
        },
        {
            name: '',
            selector: row => row.actions,
            center:true,
            width:"300px",
            cell: row => <>
                            { user.cities_edit !== 0 ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                            { user.cities_delete !== 0 ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
                        </>,
        },
    ];
    
    useEffect(()=>  {
        getCities();
        getCountries();
    },[]);

    const getCountries = () => {

        axiosClient.get("/countries").then((response) => {

            //console.log(response.data);
            setCountries(response.data);
            
        }).catch((error)=> {

            console.log(error);
           
        });

    }

    const getCities = () => {

        if (perantCountry && perantCountry.id) {
            axiosClient.get(`/cities/${perantCountry.id}`).then((response) => {

                console.log(response.data);
                setCities(response.data);
               
            }).catch((error)=> {
    
                console.log(error);
            });
        }
        else
        {
            axiosClient.get("/cities").then((response) => {

                console.log(response.data);
                setCities(response.data);
               
            }).catch((error)=> {
    
                console.log(error);
            });
        }
 
    }

    const onView = (selectedRow) => 
    {
        // setErrors(null);

        if(selectedRow)
        {
            setCity(selectedRow);
        }
        else
        {
            setCity(initRowValue);
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = () => {
        // setErrors(null);

        if(city.id)
        {
            console.log("Edit");
            console.log(city);

            axiosClient.put(`/cities/${city.id}`,city).then((response) => {

                getCities();
                //console.log("Response : " , response)
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${city.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
    
            }).catch((error)=> {
    
                const response = error.response;

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    // setErrors(response.data.errors);

                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }
                }
                else
                {
                    console.log(response);
                }

            });
        }
        else
        {
            console.log("Add");

            axiosClient.post(`/cities`,city).then((response) => {

                getCities();
                //console.log("Response : " , response);

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${city.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                  });
    
            }).catch((error)=> {
    
                const response = error.response;

                console.log(response);

                if(response && response.status === 422)
                {
                
                  if(response.data.errors)
                  {
                    // setErrors(response.data.errors);

                    Object.keys(response.data.errors).map(key => (
                        toast.error(response.data.errors[key][0])
                      ));
                  }

                }
                else
                {
                    console.log(response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
          }).then((result) => {

            if (result.isConfirmed) 
            {

                axiosClient.delete(`/cities/${selectedRow.id}`).then((response) => {

                    console.log(response);
                    getCities();

                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${city.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                      });

                }).catch((error) => {

                    console.log(error);
                });

            }
          })

    }


  return (
    <div className="content-wrapper">
    
    <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">{perantCountry && perantCountry.en_name} Cities</h1>
            </div>
       
            <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to={"/dashboard/countries"} className="text-success">Countries</Link></li>

                <li className="breadcrumb-item active">{perantCountry && perantCountry.en_name} Cities</li>
            </ol>
            
            </div>
           
        </div>
        
        </div>
       
    </div>

    <div className="content">
        <div className="container-fluid">
        <div className="row">
            <div className="col-12">
            <div className="card">
                <div className="card-header text-right">
                     {user &&  user.cities_add !== 0 ? <button onClick={ev => onView(null)}  type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New City</button> : "" }
                </div>
              
                <div className="card-body">
                    <div className="row ">
                        <div className="form-group offset-sm-10 col-sm-2 ">
                            <div className="input-group">
                            <input  type="search" className="form-control " placeholder="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-default">
                                <i className="fa fa-search" />
                                </button>
                            </div>
                            </div>

                        </div>

                    </div>

                    <DataTable className="table table-bordered" columns={columns} data={cities} pagination />

                </div>
            </div>
            
            </div>
        
        </div>
        </div>
    </div>


        <button style={{display:"none"}}  ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
        <div  className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

            <div className="modal-header">
                <h5 className="modal-title text-center" id="exampleModalLabel">{city && city.id  ? "Edit City" : "Add City"}</h5>
                <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div className="modal-body">
            {/* {
            errors && <div className="alert alert-danger " role='alert'>
                {
                Object.keys(errors).map(key => (
                    <p key={key}>{errors[key][0]}</p>
                ))
                }
            </div>
            } */}

            <form className="form-horizontal">
                <div className="form-group row">
                        <div className="offset-sm-3 col-sm-9">
                            <div className="form-check">

                            <input className="form-check-input " 
                                    checked={city.is_active}
                                    onChange={ev => setCity({...city,is_active: Number(ev.target.checked)})}
                                    type="checkbox" 
                                    id="ckActive" />

                            <label className="form-check-label" htmlFor="ckActive">Active</label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="txtArName" className="col-sm-3 col-form-label">Name ( Arabic )</label>
                        <div className="col-sm-9">

                            <input type="text" 
                                value={city.ar_name}
                                onChange={ev => setCity({...city,ar_name: ev.target.value})}
                                className="form-control" 
                                id="txtArName" 
                                placeholder="Arabic Name" />
                        </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="txtEnName" className="col-sm-3 col-form-label">Name ( English )</label>
                        <div className="col-sm-9">
                            <input type="text" 
                                value={city.en_name}
                                onChange={ev => setCity({...city,en_name: ev.target.value})}
                                className="form-control" 
                                id="txtEnName" 
                                placeholder="English Name" />
                        </div>
                </div>

                <div className="form-group row">
                        <label htmlFor="sctCountries" className="col-sm-3 col-form-label">Country</label>
                        <div className="col-sm-6">
                            
                            <select id="sctCountries"
                                    // disabled={perantCountry && perantCountry.id ? true : false}
                                    value={city.country && city.country.id ? city.country && city.country.id : 0 }
                                    onChange={ev => setCity({...city,country: countries.find(o => o.id === Number(ev.target.value))})}
                                    className="form-control form-select form-select-lg" 
                                    aria-label="Default select example">
                                    <option value={0}>Select Country</option>
                                    {countries && countries.map(countryRow => <option key={countryRow.id} value={countryRow.id}>{countryRow.en_name}</option>)}
                            </select>
                        </div>
                </div>


                </form>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" onClick={onSave} className="btn btn-success">{ city && city.id  ? "Save" : "Add"}</button>
            </div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default Cities;